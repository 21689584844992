<template>
  <v-row>
    <v-col md="12" lg="12" sm="12">
      <v-card class="mx-auto p-2" max-width="100%">
        <v-card-title class="text-sm font-bold"
          >5 Tipe Usaha Teratas</v-card-title
        >
        <v-card-text>
          <v-select
            style="width: 60%; font-size: 10px; border-color: #ffffff"
            v-model="dateFiltered"
            :items="filters"
            @change="filterDate"
            item-text="name"
            item-value="id"
            flat
            dense
            full-width
          ></v-select>
          <div class="text-xs">
            {{ formateDate(dateRange.startDate) }} -
            {{ formateDate(dateRange.endDate) }}
          </div>
          <v-list three-line>
            <p v-if="isLoading">Memuat...</p>
            <div v-if="tops.length > 0 && !isLoading" >
              <div v-for="(item, index) in tops" :key="index">
                <v-list-item
                  :key="item.title"
                  style="padding-left: 0px; padding-right: 0px"
                >
                  <v-list-item-content>
                    <v-list-item-title v-html="item.title"></v-list-item-title>
                    <v-list-item-subtitle>
                      <div class="float-left">
                        <div class="text-sm">Total</div>
                      </div>
                      <div class="float-right">
                        <div class="mr-auto text--primary font-bold float-right">
                          {{ formateRupiah(item.total) }}
                        </div>
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </div>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
    <SnackBar :snackbar="isError" :msg="errorCause" @close="closeError" />
  </v-row>
</template>
<script>
import { HomeControllers } from "@/controllers/HomeControllers";
import SnackBar from "@/views/Components/snackbar.vue";
import moment from "moment";
import { dateToDateString, filterDates, formatPrice } from "@/utils/helper.js";

export default {
  props: {
    filters: {
      default: [],
    },
    tops: []
  },
  components: {
    SnackBar,
  },
  data: () => ({
    dateFiltered: 0,
    home: new HomeControllers(false, false, ""),
     dateRange: {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
  }),
  computed: {
    isLoading() {
      return this.home.loading;
    },
    isError() {
      return this.home.error;
    },
    errorCause() {
      return this.home.errorCause;
    },
  },
  created() {
    this.getTotal();
  },
  methods: {
    async prosesTotal(module, startDate, endDate) {
      return this.home.getTotaActiveProfiles(4, module, startDate, endDate, 5);
    },
    async getTotal() {
      await this.prosesTotal(
        "top-business",
        this.dateRange.startDate,
        this.dateRange.endDate
      );

      if (!this.isError) {
        this.tops = this.home.tops.map(item => {
          return {
            title: item.type,
            total: item.total,
          }
        })
      }
    },
    async filterDate(event) {
      this.tops = []
      const daterange = filterDates(event);

      this.dateRange.startDate = daterange.startDate;
      this.dateRange.endDate = daterange.endDate;

      await this.prosesTotal(
       "top-business",
        daterange.startDate,
        daterange.endDate
      );

      if (!this.isError) {
        this.tops = this.home.tops.map(item => {
          return {
            title: item.type,
            total: item.total
          }
        })
      }
    },
    closeError(status) {
      this.home.setError(status);
    },
    formateDate(date) {
      return dateToDateString(date);
    },
    formateRupiah(value) {
      return formatPrice(value);
    },
  }
};
</script>