import axios from 'axios'
import { uploadFile } from '../endpoint/index'
import { BaseUrl } from '../endpoint/BaseUrl'
import { getToken } from '../../utils/cookies'

const headers = {
    'Authorization': `Bearer ${getToken()}`,
    'Content-Type': 'multipart/form-data'
};

export const uploadFileRequest = (file, bucket) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    bodyFormData.append('bucket', bucket);
    let resp = null
    resp = axios({
        method: 'post',
        url: `${BaseUrl()}${uploadFile()}`,
        data: bodyFormData,
        headers: headers
    }).then((response) => {
        return response
    })
    return resp
}


