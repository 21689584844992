<template>
  <div class="mx-5 my-20">
    <v-row>
      <v-col md="12" lg="12" sm="12">
        <p class="font-bold text-lg">Pemberitahuan</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" lg="12" sm="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr class="bg-gray-kc-100">
                <th class="text-left">ID</th>
                <th class="text-left">Judul</th>
                <th class="text-left">Deskripsi</th>
                <th class="text-left">Tanggal</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isLoading">
                <td colspan="6">Memuat...</td>
              </tr>
              <tr v-else-if="notifList.length === 0 && !isLoading">
                <td colspan="6">Data not found</td>
              </tr>
              <tr
                v-else
                v-for="(item, index) in notifList"
                :key="index"
                class="cursor-pointer"
              >
                <td>{{ item.id }}</td>
                <td>{{ item.title }}</td>
                <td>{{ item.description }}</td>
                <td>{{ formateDate(item.createdAt) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col md="12" sm="12" class="text-lg-right">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} dari {{ pages.end }}
        </span>
        <v-btn text class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn text class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <SnackBar :snackbar="isError" :msg="errorCause" @close="closeError" />
  </div>
</template>
<script>
import { NotificationController } from "@/controllers/NotificationController";
import SnackBar from "@/views/Components/snackbar.vue";
import { dateToDateString } from "@/utils/helper.js";

export default {
  components: {
    SnackBar
  },
  data: () => ({
    notifikasi: new NotificationController(false, false, ""),
    meta: {
      page: 1,
      limit: 10,
    },
    pages: {
      start: 1,
      end: 10,
    },
  }),
  computed: {
    notifList() {
      return this.notifikasi.listDashboard;
    },
    isLoading() {
      return this.notifikasi.loadingListDashboard;
    },
    isError() {
      return this.notifikasi.errorListDashboard;
    },
    errorCause() {
      return this.notifikasi.errorCauseListDashboard;
    },
  },
  created() {
    this.getNotifikasi();
  },
  methods: {
    async getNotifikasiList(page, limit) {
      return this.notifikasi.getNotificationDashboardList(page, limit);
    },
    async getNotifikasi() {
      await this.getNotifikasiList(
        this.meta.page,
        this.meta.limit,
      );
    },
    async formerPage() {
      if (this.pages.start > 10) {
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.meta.page -= 1;

        await this.getNotifikasiList(
            this.meta.page,
            this.meta.limit,
        );
      }
    },
    async nextPage() {
      this.pages.start += 10;
      this.pages.end += 10;

      this.meta.page += 1;

      await this.getNotifikasiList(
        this.meta.page,
        this.meta.limit,
      );
    },
    closeError(status) {
      this.notifikasi.setErrorListDashboard(status);
    },
    formateDate(date) {
      return dateToDateString(date);
    },
  },
};
</script>
