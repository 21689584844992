<template>
  <div>
    <v-toolbar flat elevation="0">
      <div class="pl-10">
        <img
          :src="require(`@/assets/svg/logo.svg`)"
        />
      </div>
    </v-toolbar>
  </div>
</template>
<script>
export default {
  name: "Navbar",
};
</script>