import { GetTotalRequest } from '../api/request/HomeRequest'

export class HomeControllers {
    // active profiles
    loading = false;
    error = false;
    errorCause = "";
    totalActiveUser = 0;
    totalNonActiveUser = 0;
    totalTransaction = 0;
    totalsubscriber = null;
    tops = []

    constructor(loading, error, errorCause) {
        this.loading = loading;
        this.error = error;
        this.errorCause = errorCause;
    }

    getTotaActiveProfiles(type, module, startDate, endDate, limit) {
        this.setLoading(true)
        let resp = null;
        resp = GetTotalRequest(module, startDate, endDate, limit)
            .then((response) => {
                if(type === 0) {
                    this.setTotalActiveUser(response.data.data[0].total)
                } else if(type === 1) {
                    this.setTotalNonActiveUser(response.data.data[0].total)
                } else if(type === 2) {
                    this.setTotalTransaction(response.data.data[0].total)
                } else if(type === 3){
                    this.setTotalSubscriber(response.data.data)
                } else if(type === 4){
                    this.setTotalTop(response.data.data)
                }

                this.setErrorCause("")
                this.setError(false)
                return true
            }).catch((err) => {
                if(type === 0) {
                    this.setTotalActiveUser(0)
                } else if(type === 1) {
                    this.setTotalNonActiveUser(0)
                } else if(type === 2) {
                    this.setTotalTransaction(0)
                } else if(type === 3){
                    this.setTotalSubscriber({
                        items: [],
                        total: 0
                    })
                } else if(type === 4) {
                    this.setTotalTop([])
                }
                if(err.response.data.code === 404) {
                    this.setErrorCause('Data Tidak Ditemukan')
                    this.setError(false)
                } else {
                    this.setErrorCause(err)
                    this.setError(true)
                }
                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }

    setLoading(status) {
        this.loading = status;
    }

    setTotalActiveUser(total) {
        this.totalActiveUser = total;
    }

    setTotalNonActiveUser(total) {
        this.totalNonActiveUser = total;
    }

    setTotalTransaction(total) {
        this.totalTransaction = total;
    }

    setTotalSubscriber(total) {
        this.totalsubscriber = total;
    }

    setTotalTop(data) {
        this.tops = data;
    }

    setError(status) {
        this.error = status; 
    }

    setErrorCause(err) {
        this.errorCause = err;
    }
}
