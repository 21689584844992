import axios from 'axios'
import { getTotalTransaction, getTransactionList, getTransactionById } from '../endpoint/index'
import { BaseUrl } from '../endpoint/BaseUrl'
import { getToken } from '../../utils/cookies'

const headers = {
    'Authorization': `Bearer ${getToken()}`,
};

export const getTotalTransactionRequest = (startDate, endDate) => {
    let resp = null
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getTotalTransaction(startDate, endDate)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const getTransactionListRequest = (page, limit, startDate, endDate, sort, keyword) => {
    let resp = null
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getTransactionList(page, limit, startDate, endDate, sort, keyword)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const getTransactionByIdRequest = (id) => {
    let resp = null
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getTransactionById(id)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}
