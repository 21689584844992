import { getFAQListRequest, createFAQRequest, getFaqByIdRequest, updateFaqRequest, deleteFaqByIdRequest } from '../api/request/FAQRequest'

export class FAQControllers {
    // list
    loadingList = false;
    errorList = false;
    errorCauseList = "";
    list = []

    // create
    loadingCreate = false;
    msgCreate = "";
    errorCreate = false;
    errorCauseCreate = "";

    // detail
    loadingDetail = false;
    errorDetail = false;
    errorCauseDetail = "";
    detail = {}
 

    constructor(loading, error, errorCause) {
        this.loading = loading;
        this.error = error;
        this.errorCause = errorCause;
    }

    getFAQList(page, limit, keyword) {
        this.setLoadingList(true)
        let resp = null;
        resp = getFAQListRequest(page, limit, keyword)
            .then((response) => {
                this.setFAQList(response.data.data)
                this.setErrorCauseList("")
                this.setErrorList(false)
                return true
            }).catch((err) => {
                this.setFAQList([])
                if (err.response.data.code === 404) {
                    this.setErrorCauseList('Data Tidak Ditemukan')
                    this.setErrorList(false)
                } else {
                    this.setErrorCauseList(err)
                    this.setErrorList(true)
                }
                return false
            }).finally(() => {
                this.setLoadingList(false)
            })
        return resp
    }

    // list
    setLoadingList(status) {
        this.loadingList = status;
    }

    setFAQList(data) {
        this.list = data;
    }

    setErrorCauseList(err) {
        this.errorCauseList = err;
    }

    setErrorList(status) {
        this.errorList = status;
    }

    //create
    createFAQ(data) {
        this.setLoadingCreate(true)
        let resp = null;
        resp = createFAQRequest(data)
            .then((response) => {
                this.setMsgSuccess(response.data.message)
                this.setErrorCauseCreate("")
                this.setErrorCreate(false)
                return true
            }).catch((err) => {
                this.setMsgSuccess("")
                this.setErrorCauseCreate(err)
                this.setErrorCreate(true)
                
                return false
            }).finally(() => {
                this.setLoadingCreate(false)
            })
        return resp
    }

    setMsgSuccess(msg) {
        this.msgCreate = msg;
    }

    setLoadingCreate(status) {
        this.loadingCreate = status;
    }

    setErrorCauseCreate(err) {
        this.errorCauseCreate = err;
    }

    setErrorCreate(status) {
        this.errorCreate = status;
    }

    // detail
    getFaqById(id) {
        this.setLoadingDetail(true)
        let resp = null;
        resp = getFaqByIdRequest(id)
            .then((response) => {
                this.setFaqDetail(response.data.data)
                this.setErrorCauseDetail("")
                this.setErrorDetail(false)
                return true
            }).catch((err) => {
                this.setFaqDetail({})
                if (err.response.data.code === 404) {
                    this.setErrorCauseDetail('Data Tidak Ditemukan')
                    this.setErrorDetail(false)
                } else {
                    this.setErrorCauseDetail(err)
                    this.setErrorDetail(true)
                }
                return false
            }).finally(() => {
                this.setLoadingDetail(false)
            })
        return resp
    }

    // update
    updateFaq(id, data) {
        this.setLoadingCreate(true)
        let resp = null;
        resp = updateFaqRequest(id, data)
            .then((response) => {
                this.setMsgSuccess(response.data.message)
                this.setErrorCauseCreate("")
                this.setErrorCreate(false)
                return true
            }).catch((err) => {
                this.setMsgSuccess("")
                this.setErrorCauseCreate(err)
                this.setErrorCreate(true)
                
                return false
            }).finally(() => {
                this.setLoadingCreate(false)
            })
        return resp
    }

    // delete
    deleteFaqById(id) {
        this.setLoadingCreate(true)
        let resp = null;
        resp = deleteFaqByIdRequest(id)
            .then((response) => {
                this.setMsgSuccess(response.data.message)
                this.setErrorCauseCreate("")
                this.setErrorCreate(false)
                return true
            }).catch((err) => {
                this.setMsgSuccess("")
                this.setErrorCauseCreate(err)
                this.setErrorCreate(true)
                
                return false
            }).finally(() => {
                this.setLoadingCreate(false)
            })
        return resp
    }

    //detail
    setLoadingDetail(status) {
        this.loadingDetail = status;
    }

    setFaqDetail(data) {
        this.detail = data;
    }

    setErrorCauseDetail(err) {
        this.errorCauseDetail = err;
    }

    setErrorDetail(err) {
        this.errorDetail = err;
    }
}
