export const defineRole = (role, menu) => {
    const roleStored = {
        "admin": {
            "dashboard": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "users": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "usaha": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "transaksi": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "subscription": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "analysisReport": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "videoRekomendasi": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "notification": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "faq": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "admin": {
                create: true,
                read: true,
                update: true,
                del: true
            }
        },
        "owner": {
            "dashboard": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "users": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "usaha": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "transaksi": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "subscription": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "analysisReport": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "videoRekomendasi": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "notification": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "faq": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "admin": {
                create: false,
                read: false,
                update: false,
                del: false
            }
        },
        "thirdparty": {
            "dashboard": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "users": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "usaha": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "transaksi": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "subscription": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "analysisReport": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "videoRekomendasi": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "notification": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "faq": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "admin": {
                create: false,
                read: false,
                update: false,
                del: false
            }
        }
    }

    return roleStored[role][menu];
}