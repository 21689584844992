import axios from 'axios'
import { getSubscriptionList, getSubscriptionById, createSubscription } from '../endpoint/index'
import { BaseUrl } from '../endpoint/BaseUrl'
import { getToken } from '../../utils/cookies'

const headers = {
    'Authorization': `Bearer ${getToken()}`,
};

export const getSubscriptionListRequest = (page, limit, startDate, endDate, sort, keyword) => {
    let resp = null
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getSubscriptionList(page, limit, startDate, endDate, sort, keyword)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const getSubscriptionByIdRequest = (id) => {
    let resp = null
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getSubscriptionById(id)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const createSubscriptionRequest = (data) => {
    let resp = null
    resp = axios({
        method: 'post',
        data: data,
        url: `${BaseUrl()}${createSubscription()}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}
