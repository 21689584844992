import { getAdminListRequest, createAdminRequest, getAdminByIdRequest } from '../api/request/AdminRequest'

export class AdminControllers {
    // list
    loadingList = false;
    errorList = false;
    errorCauseList = "";
    list = []

    // create
    loadingCreate = false;
    msgCreate = "";
    errorCreate = false;
    errorCauseCreate = "";

    // detail
    loadingDetail = false;
    errorDetail = false;
    errorCauseDetail = "";
    detail = {}

    constructor(loading, error, errorCause) {
        this.loading = loading;
        this.error = error;
        this.errorCause = errorCause;
    }

    getAdminList(page, limit, keyword) {
        this.setLoadingList(true)
        let resp = null;
        resp = getAdminListRequest(page, limit, keyword)
            .then((response) => {
                this.setAdminList(response.data.data)
                this.setErrorCauseList("")
                this.setErrorList(false)
                return true
            }).catch((err) => {
                this.setAdminList([])
                if (err.response.data.code === 404) {
                    this.setErrorCauseList('Data Tidak Ditemukan')
                    this.setErrorList(false)
                } else {
                    this.setErrorCauseList(err)
                    this.setErrorList(true)
                }
                return false
            }).finally(() => {
                this.setLoadingList(false)
            })
        return resp
    }

    // list
    setLoadingList(status) {
        this.loadingList = status;
    }

    setAdminList(data) {
        this.list = data;
    }

    setErrorCauseList(err) {
        this.errorCauseList = err;
    }

    setErrorList(status) {
        this.errorList = status;
    }

    //create
    createAdmin(data) {
        this.setLoadingCreate(true)
        let resp = null;
        resp = createAdminRequest(data)
            .then((response) => {
                this.setMsgSuccess(response.data.message)
                this.setErrorCauseCreate("")
                this.setErrorCreate(false)
                return true
            }).catch((err) => {
                this.setMsgSuccess("")
                this.setErrorCauseCreate(err)
                this.setErrorCreate(true)
                
                return false
            }).finally(() => {
                this.setLoadingCreate(false)
            })
        return resp
    }

    setMsgSuccess(msg) {
        this.msgCreate = msg;
    }

    setLoadingCreate(status) {
        this.loadingCreate = status;
    }

    setErrorCauseCreate(err) {
        this.errorCauseCreate = err;
    }

    setErrorCreate(status) {
        this.errorCreate = status;
    }

    // detail
    getAdminById(id) {
        this.setLoadingDetail(true)
        let resp = null;
        resp = getAdminByIdRequest(id)
            .then((response) => {
                this.setAdminDetail(response.data.data)
                this.setErrorCauseDetail("")
                this.setErrorDetail(false)
                return true
            }).catch((err) => {
                this.setAdminDetail({})
                if (err.response.data.code === 404) {
                    this.setErrorCauseDetail('Data Tidak Ditemukan')
                    this.setErrorDetail(false)
                } else {
                    this.setErrorCauseDetail(err)
                    this.setErrorDetail(true)
                }
                return false
            }).finally(() => {
                this.setLoadingDetail(false)
            })
        return resp
    }

    setLoadingDetail(status) {
        this.loadingDetail = status;
    }

    setAdminDetail(data) {
        this.detail = data;
    }

    setErrorCauseDetail(err) {
        this.errorCauseDetail = err;
    }

    setErrorDetail(err) {
        this.errorDetail = err;
    }

    
}
