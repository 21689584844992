import { getReportAnalysisRequest } from '../api/request/ReportAnalysisRequest'

export class ReportAnalysisController {
    loadingList = false;
    errorList = false;
    errorCauseList = "";
    data = {}


    constructor(loading, error, errorCause) {
        this.loading = loading;
        this.error = error;
        this.errorCause = errorCause;
    }

    getReportAnalysis(module, year, businessType) {
        this.setLoadingList(true)
        let resp = null;
        resp = getReportAnalysisRequest(module, year, businessType)
            .then((response) => {
                this.setData(response.data.data)
                this.setErrorCauseList("")
                this.setErrorList(false)
                return true
            }).catch((err) => {
                this.setFAQList([])
                if (err.response.data.code === 404) {
                    this.setErrorCauseList('Data Tidak Ditemukan')
                    this.setErrorList(false)
                } else {
                    this.setErrorCauseList(err)
                    this.setErrorList(true)
                }
                return false
            }).finally(() => {
                this.setLoadingList(false)
            })
        return resp
    }

    setLoadingList(status) {
        this.loadingList = status;
    }

    setData(data) {
        this.data = data;
    }

    setErrorCauseList(err) {
        this.errorCauseList = err;
    }

    setErrorList(status) {
        this.errorList = status;
    }
}
