<template>
  <v-row>
    <v-col md="12" lg="12" sm="12">
      <v-card class="mx-auto" max-width="100%">
        <v-card-title>
          <v-row>
            <v-col md="8" lg="8" sm="12">
              <span class="text-base font-bold"
                >Total Transaksi Jenis Usaha</span
              >
            </v-col>
            <v-col md="4" lg="4" sm="12">
              <div class="text-xs flex">
                <div class="text-xs text-gray-kc-200 pt-3 pr-1">
                  {{ formateDate(dateRange.startDate) }} -
                  {{ formateDate(dateRange.endDate) }}
                </div>
                <v-select
                  class="ml-2"
                  style="width: 20%; font-size: 10px; border-color: #ffffff"
                  v-model="dateFiltered"
                  :items="filters"
                  @change="filterDate"
                  item-text="name"
                  item-value="id"
                  flat
                  dense
                ></v-select>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              v-for="(card, index) in cards"
              :key="index"
              md="3"
              lg="3"
              sm="12"
            >
              <v-card outlined class="mx-auto" max-width="100%">
                <v-card-title class="text-sm font-bold">
                  {{ card.type }}
                </v-card-title>
                <v-card-text class="flex">
                  <span class="text-sm text-gray-kc-200">Total</span>
                  <v-spacer></v-spacer>
                  <div class="text-sm">
                    {{ formateRupiah(card.total) }}
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" lg="3" sm="12" v-if="cards.length > 6">
              <v-card
                flat
                class="mx-auto py-4"
                style="background-color: #f0f0f0"
                max-width="100%"
              >
                <v-card-title
                  v-if="cardToogle"
                  class="text-sm font-bold cursor-pointer"
                  @click="scrollCard"
                >
                  Sembunyikan
                  <v-spacer></v-spacer>
                  <v-icon class="mr-5">mdi-arrow-up-circle-outline</v-icon>
                </v-card-title>
                <v-card-title
                  v-else
                  class="text-sm font-bold cursor-pointer"
                  @click="scrollCard"
                >
                  Lihat Selengkapnya
                  <v-spacer></v-spacer>
                  <v-icon class="mr-5">mdi-arrow-down-circle-outline</v-icon>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <SnackBar :snackbar="isError" :msg="errorCause" @close="closeError" />
  </v-row>
</template>
<script>
import { BusinessController } from "@/controllers/BusinessController";
import SnackBar from "@/views/Components/snackbar.vue";
import moment from "moment";
import { dateToDateString, formatPrice, filterQuickDates } from "@/utils/helper.js";

export default {
  components: {
    SnackBar,
  },
  data: () => ({
    dateFiltered: 0,
    filters: [
      {
        id: 0,
        name: "Minggu ini",
      },
      {
        id: 1,
        name: "Bulan ini",
      },
      {
        id: 2,
        name: "3 Bulan terakhir",
      },
    ],

    business: new BusinessController(false, false, ""),
    cardToogle: false,
    dateRange: {
      startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      endDate: moment().format("YYYY-MM-DD"),
    },
    dataCards: [],
  }),
  computed: {
    cards: {
      get() {
        if (this.cardToogle) {
          return this.dataCards;
        } else {
          return this.business.businessData.filter((item, index) => index < 7);
        }
      },
      set(val) {
        this.dataCards = val;
      },
    },
    isLoading() {
      return this.business.loading;
    },
    isError() {
      return this.business.error;
    },
    errorCause() {
      return this.business.errorCause;
    },
  },
  created() {
    this.getBusiness();
  },
  methods: {
    async prosesBusiness(startDate, endDate) {
      return this.business.getTotalBuiness(startDate, endDate);
    },
    async getBusiness() {
      await this.prosesBusiness(
        this.dateRange.startDate,
        this.dateRange.endDate
      );
    },
    scrollCard() {
      this.cardToogle = !this.cardToogle;
      const newCards = this.business.businessData.map((item) => item);

      this.cards = newCards;
    },
    closeError(status) {
      this.business.setError(status);
    },
    formateDate(date) {
      return dateToDateString(date);
    },
    formateRupiah(value) {
      return formatPrice(value);
    },
    async filterDate(event) {
      this.tops = []
      const daterange = filterQuickDates(event);

      this.dateRange.startDate = daterange.startDate;
      this.dateRange.endDate = daterange.endDate;

      await this.prosesBusiness(
        daterange.startDate,
        daterange.endDate
      );
    },
  },
};
</script>