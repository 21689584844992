<template>
  <v-row>
    <v-col sm="12" lg="3">
      <v-card outlined class="mx-auto" max-width="100%">
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-sm font-bold mb-4">Piutang Usaha</div>
            <v-list-item-title class="text-sm">
              {{ loading ? "Memuat..." : accountReceivable }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-avatar tile size="24">
            <span class="mdi mdi-bag-checked mdi-24px"></span>
          </v-list-item-avatar>
        </v-list-item>
      </v-card>
    </v-col>

    <v-col sm="12" lg="3">
      <v-card outlined class="mx-auto" max-width="100%">
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-sm font-bold mb-4">Persediaan</div>
            <v-list-item-title class="text-sm">
              {{ loading ? "Memuat..." : supply }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-avatar tile size="24">
            <span class="mdi mdi-package mdi-24px"></span>
          </v-list-item-avatar>
        </v-list-item>
      </v-card>
    </v-col>

    <v-col sm="12" lg="3">
      <v-card outlined class="mx-auto" max-width="100%">
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-sm font-bold mb-4">Hutang KUR</div>
            <v-list-item-title class="text-sm">
              {{ loading ? "Memuat..." : accountPayableKur }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-avatar tile size="24">
            <span class="mdi mdi-bank mdi-24px"></span>
          </v-list-item-avatar>
        </v-list-item>
      </v-card>
    </v-col>

    <v-col sm="12" lg="3">
      <v-card outlined class="mx-auto" max-width="100%">
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-sm font-bold mb-4">Hutang Non KUR</div>
            <v-list-item-title class="text-sm">
              {{ loading ? "Memuat..." : accountPayable }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-avatar tile size="24">
            <span class="mdi mdi-piggy-bank-outline mdi-24px"></span>
          </v-list-item-avatar>
        </v-list-item>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    loading: {
      default: false,
    },
    cashOnHand: {
      default: "0",
    },
    accountReceivable: {
      default: "0",
    },
    supply: {
      default: "0",
    },
    accountPayable: {
      default: "0",
    },
    accountPayableKur: {
      default: "0"
    }
  },
  data: () => ({}),
};
</script>
