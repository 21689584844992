<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="800">
        <v-card class="rounded-3xl">
          <v-card-title class="text-base font-bold pb-10">
            <span>Datail Pelanggan</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-card class="rounded-3xl">
              <v-card-title>
                <span class="text-base font-bold">Rincian Langganan</span>
                <v-spacer></v-spacer>
                <div class="text-sm">
                  <v-btn small text class="title-case">
                  <v-icon>mdi-download mdi-18px</v-icon> Download Invoice
                  </v-btn>
                </div>
              </v-card-title>
              <v-card-text> <strong>No Invoice: {{ data.invoice }} </strong></v-card-text>
              <v-card-text class="flex">
                <v-text-field
                  label="Nama Pengguna"
                  class="mr-5"
                  v-model="data.profile.name"
                  readonly
                ></v-text-field>
                <v-text-field
                  label="Tgl. Berlangganan"
                  class="mr-5"
                  v-model="data.date"
                  readonly
                ></v-text-field>
                <v-text-field
                  label="Dibuat oleh"
                  class="mr-5"
                  v-model="data.admin.name"
                  readonly
                ></v-text-field>
              </v-card-text>
            </v-card>

            <v-card class="rounded-3xl mt-5">
              <v-card-title>
                <span class="text-base font-bold">Profil Pengguna</span>
                <v-spacer></v-spacer>
                <div class="text-sm text-gray-kc-200">
                  Tgl. Registrasi: {{ formateDate(data.createdAt) }}
                </div>
              </v-card-title>
              <v-card-text><strong> ID:</strong> {{ data.id }}</v-card-text>
              <v-card-text class="flex">
                <v-text-field
                  label="Nama"
                  class="mr-5"
                  v-model="data.profile.name"
                  readonly
                ></v-text-field>
                <v-text-field
                  label="No Handphone"
                  class="mr-5"
                  v-model="data.profile.mobileNumber"
                  readonly
                ></v-text-field>
                <v-text-field
                  label="Email"
                  class="mr-5"
                  v-model="data.profile.email"
                  readonly
                ></v-text-field>
              </v-card-text>
            </v-card>

            <v-card class="rounded-3xl mt-5">
              <v-card-title>
                <span class="text-base font-bold">Karyawan</span>
              </v-card-title>
              <v-card-text class="flex">

                <v-list-item two-line v-for="(item, index) in data.listStaff" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.email }}</v-list-item-title>
                    <v-list-item-subtitle class="text-gray-kc-200">{{ item.role }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                
              </v-card-text>
            </v-card>

          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <SnackBar :snackbar="isError" :msg="errorCause" @close="closeError" />
  </div>
</template>
<script>
import { SubscriptionControllers } from "@/controllers/SubscriptionControllers";
import { dateToDateString } from "@/utils/helper.js";
import SnackBar from "@/views/Components/snackbar.vue";

export default {
  data: () => ({
    profile: new SubscriptionControllers(false, false, ""),
    dialog: false,
  }),
  components: {
    SnackBar
  },
  computed: {
    data() {
      return {
        ...this.profile.detail,
        date: this.formateDate(this.profile.detail.createdAt)
      }
    },
    isLoading() {
      return this.profile.loadingDetail;
    },
    isError() {
      return this.profile.errorDetail;
    },
    errorCause() {
      return this.profile.errorCauseDetail;
    },
  },
  methods: {
    async getProfileId(id) {
      return this.profile.getSubscriptionById(id);
    },
    async openDialog(id) {
      this.dialog = true;
      await this.getProfileId(id);
    },
    formateDate(date) {
      return dateToDateString(date);
    },
    closeError(status) {
      this.profile.setErrorDetail(status);
    },
  },
};
</script>
<style scoped>
.v-sheet.v-card {
  border-radius: 10px;
}
.title-case {
  text-transform: none;
}
</style>
