<template>
  <div class="mx-5 my-20">
    <v-row>
      <v-col md="12" lg="12" sm="12">
        <p class="font-bold text-lg">Transaksi</p>
      </v-col>
    </v-row>
    <Cards />
    <v-row>
      <v-col md="9" lg="9" sm="12" class="flex">
        <v-chip
          outlined
          class="mt-2 mr-2 cursor-pointer"
          @click="filter = true"
        >
          <v-icon>mdi-filter-menu-outline</v-icon> Filter
        </v-chip>
        <v-chip-group
          v-model="filterObject.quickDate"
          active-class="grey lighten-2 black--text"
          column
          @change="filterQuickDate"
        >
           <v-chip outlined>Minggu Ini</v-chip>
          <v-chip outlined>Bulan Ini</v-chip>
          <v-chip outlined>3 Bulan Terakhir</v-chip>
        </v-chip-group>
      </v-col>
      <v-col md="3" lg="3" sm="12">
        <v-text-field
          v-model="meta.keyword"
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          prepend-inner-icon="mdi-file-search-outline"
          placeholder="Cari Transaksi"
          outlined
          rounded
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" lg="12" sm="12">
        Menampilkan daftar transaksi <strong>{{ currentStatus }}</strong>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" lg="12" sm="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr class="bg-gray-kc-100">
                <th class="text-left">ID Transaksi</th>
                <th class="text-left">Jenis Transaksi</th>
                <th class="text-left">Kategori Transaksi</th>
                <th class="text-left">Total (Rp)</th>
                <th class="text-left">Pengguna</th>
                <th class="text-left">Tgl. Transaksi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isLoading">
                <td colspan="6">Memuat...</td>
              </tr>
              <tr v-else-if="transactionList.length === 0 && !isLoading">
                <td colspan="6">
                  Data not found
                </td>
              </tr>
              <tr
                v-else
                v-for="(item, index) in transactionList"
                :key="index"
                class="cursor-pointer"
                @click="getDetail(item.id)"
              >
                <td>{{ item.id }}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.category }}</td>
                <td>{{ formateRupiah(item.totalAmount) }}</td>
                <td>{{ item.profile.business ? item.profile.business.name : "-" }}</td>
                <td>{{ formateDate(item.createdAt) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col md="12" sm="12" class="text-lg-right">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} dari {{ metaPages.totalData }}
        </span>
        <v-btn text class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn v-if="meta.page === metaPages.totalPage" disabled text class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn v-else text class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <Detail ref="openDialog" />
    <v-dialog v-model="filter" persistent max-width="500">
      <v-card class="rounded-3xl" style="border-radius: 10px">
        <v-card-title class="text-base font-bold">
          <span>Filter</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="filter = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pl-2">
          <v-card flat>
            <v-card-title>
              <span class="text-sm font-bold">Urutkan</span>
            </v-card-title>
            <v-card-text class="flex">
              <v-chip-group
                v-model="filterObject.sort"
                active-class="grey lighten-2 black--text"
                column
              >
                <v-chip label outlined>A-Z</v-chip>
                <v-chip label outlined>Z-A</v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>
          <v-divider class="mx-4"></v-divider>
          <v-card flat>
            <v-card-title>
              <span class="text-sm font-bold">Tanggal Registrasi</span>
            </v-card-title>
            <v-card-text>
              <div class="flex">
                <v-menu
                  ref="menuDate1"
                  v-model="menuDate1"
                  :close-on-content-click="false"
                  :return-value.sync="startDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDate"
                      label="Dari Tanggal"
                      prepend-inner-icon="mdi-calendar"
                      dense
                      outlined
                      readonly
                      class="mr-1"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="startDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuDate1 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuDate1.save(startDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-menu
                  ref="menuDate2"
                  v-model="menuDate2"
                  :close-on-content-click="false"
                  :return-value.sync="endDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endDate"
                      label="Sampai Tanggal"
                      prepend-inner-icon="mdi-calendar"
                      class="ml-1"
                      dense
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="endDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuDate2 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuDate2.save(endDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
              <v-chip-group
                v-model="filterObject.quickDate"
                active-class="grey lighten-2 black--text"
                column
                @change="quickDatesFilter"
              >
                <v-chip outlined>Minggu ini</v-chip>
                <v-chip outlined>Bulan ini</v-chip>
                <v-chip outlined>3 Bulan terakhir</v-chip>
              </v-chip-group>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn rounded class="title-case" @click="filter = false">
                Batal
              </v-btn>
              <v-btn rounded class="title-case" dark @click="getTransaction">
                Terapkan
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Cards from "./components/Cards.vue";
import Detail from "./components/Detail.vue";
import moment from "moment";
import { TransactionController } from "@/controllers/TransactionController";
import { dateToDateString, filterQuickDates, formatPrice } from "@/utils/helper.js";
export default {
  components: {
    Cards,
    Detail
  },
  data: () => ({
    transaction: new TransactionController(false, false, ""),
    filter: false,
    filterObject: {
      sort: 0,
      quickDate: 0,
    },

    // date
    startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    menuDate1: false,

    endDate: moment().format("YYYY-MM-DD"),
    menuDate2: false,
    meta: {
      page: 1,
      limit: 10,
      keyword: ''
    },
    pages: {
      start: 1,
      end: 10,
    },
    search: {
      debounce: null,
    },
  }),
  computed: {
    currentStatus() {
      if (this.filterObject.quickDate === 0) {
        return "Minggu ini";
      } else if (this.filterObject.quickDate === 1) {
        return "Bulan ini";
      } else {
        return "3 Bulan terakhir";
      }
    },
    transactionList() {

      return this.transaction.list;
    },
    metaPages(){
      return this.transaction.metaList;
    },
    isLoading() {
      return this.transaction.loadingList;
    },
    isError() {
      return this.transaction.errorList;
    },
    errorCause() {
      return this.transaction.errorCauseList;
    },
  },
  created() {
    this.getTransaction();
  },
  methods: {
    async getTransactionList(page, limit, startDate, endDate, sort, keyword) {
      return this.transaction.getTransactionList(
        page,
        limit,
        startDate,
        endDate,
        sort,
        keyword
      );
    },
    async getTransaction() {
      this.filter = false;
      await this.getTransactionList(
        this.meta.page,
        this.meta.limit,
        this.startDate,
        this.endDate,
        this.filterObject.sort === 0 ? "asc" : "desc",
        this.meta.keyword
      );
    },
    async formerPage() {
      if (this.pages.start > 10) {
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.meta.page -= 1;

        await this.getTransactionList(
          this.meta.page,
          this.meta.limit,
          this.startDate,
          this.endDate,
          this.filterObject.sort === 0 ? "asc" : "desc",
          this.meta.keyword
        );
      }
    },
    async nextPage() {
      this.pages.start += 10;
      this.pages.end += 10;

      this.meta.page += 1;

      await this.getTransactionList(
        this.meta.page,
        this.meta.limit,
        this.startDate,
        this.endDate,
        this.filterObject.sort === 0 ? "asc" : "desc",
        this.meta.keyword
      );
    },
    async filterQuickDate(e) {
      if(e !== undefined) {
        this.transaction.setTransactionList([]);

        const daterange = filterQuickDates(e);

        this.startDate = daterange.startDate;
        this.endDate = daterange.endDate;

        await this.getTransactionList(
          this.meta.page,
          this.meta.limit,
          daterange.startDate,
          daterange.endDate,
          this.filterObject.sort === 0 ? "asc" : "desc",
          this.meta.keyword
        );
      }
    },
    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.meta.page = 1;
      this.meta.limit = 10;
      
      await this.getTransactionList(
        this.meta.page,
        this.meta.limit,
        this.startDate,
        this.endDate,
        this.filterObject.sort === 0 ? "asc" : "desc",
        this.meta.keyword
      );
    },
    debounceSearchEmpty(event) {
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.meta.page = 1;
          this.meta.limit = 10;
          
          await this.getTransactionList(
            this.meta.page,
            this.meta.limit,
            this.startDate,
            this.endDate,
            this.filterObject.sort === 0 ? "asc" : "desc",
            this.meta.keyword
          );
        }
      }, 500);
    },
    closeError(status) {
      this.transaction.setErrorList(status);
    },
    formateDate(date) {
      return dateToDateString(date);
    },
    formateRupiah(value) {
      return formatPrice(value);
    },
    getDetail(id) {
      this.$refs.openDialog.openDialog(id);
    },
    quickDatesFilter(event) {
      if(event !== undefined) {
        const daterange = filterQuickDates(event);
        this.startDate = daterange.startDate;
        this.endDate = daterange.endDate;
      }
    }
  }
};
</script>