<template>
  <v-snackbar v-model="snackbar" top color="error" outlined>
    {{ msg }}

    <template v-slot:action="{ attrs }">
      <v-btn color="default" text v-bind="attrs" @click="close"> Close </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  props: {
    snackbar: {
      default: false,
      type: Boolean,
    },
    msg: {
      default: "",
      type: String,
    },
  },
  data: () => ({}),
  methods: {
    close() {
      this.$emit("close", false);
    },
  },
};
</script>