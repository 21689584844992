import axios from 'axios'
import { SignIn } from '../endpoint/index'
import { BaseUrl } from '../endpoint/BaseUrl'
const token = `MTIzOjEyMw==`

export const AuthRequest = (email, password, role) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${SignIn(role)}`,
        data: {
           email: email,
           password: password
        },
        headers: {
            'Authorization': `Basic ${token}`
        },
    }).then((response) => {
        return response
    })
    return resp
}
