import { uploadFileRequest } from '../api/request/UploadRequest'

export class UploadControllers {
    // create
    loadingCreate = false;
    msgCreate = "";
    errorCreate = false;
    errorCauseCreate = "";

    constructor(loading, error, errorCause) {
        this.loadingCreate = loading;
        this.errorCreate = error;
        this.errorCauseCreate = errorCause;
    }

    //create
    uploadFile(file, bucket) {
        this.setLoadingCreate(true)
        let resp = null;
        resp = uploadFileRequest(file, bucket)
            .then((response) => {
                this.setMsgSuccess(response.data.data.url)
                this.setErrorCauseCreate("")
                this.setErrorCreate(false)
                return true
            }).catch((err) => {
                this.setMsgSuccess("")
                this.setErrorCauseCreate(err)
                this.setErrorCreate(true)
                
                return false
            }).finally(() => {
                this.setLoadingCreate(false)
            })
        return resp
    }

    setMsgSuccess(msg) {
        this.msgCreate = msg;
    }

    setLoadingCreate(status) {
        this.loadingCreate = status;
    }

    setErrorCauseCreate(err) {
        this.errorCauseCreate = err;
    }

    setErrorCreate(status) {
        this.errorCreate = status;
    }
}
