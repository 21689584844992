<template>
  <div class="mx-5 my-20">
    <v-row>
      <v-col md="9" lg="9" sm="12">
        <p class="font-bold text-lg">Admin</p>
      </v-col>
      <v-col md="3" lg="3" sm="12" class="text-lg-right">
        <v-btn rounded class="title-case" dark @click="addNew">
          <v-icon>mdi-note-plus mdi-18</v-icon>
          Tambah Admin</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col md="9" lg="9" sm="12"> </v-col>
      <v-col md="3" lg="3" sm="12">
        <v-text-field
          v-model="meta.keyword"
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          prepend-inner-icon="mdi-file-search-outline"
          placeholder="Cari Admin"
          outlined
          rounded
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" lg="12" sm="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr class="bg-gray-kc-100">
                <th class="text-left">ID</th>
                <th class="text-left">Nama</th>
                <th class="text-left">Email</th>
                <th class="text-left">Tgl. Registrasi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isLoading">
                <td colspan="6">Memuat...</td>
              </tr>
              <tr v-else-if="adminList.length === 0 && !isLoading">
                <td colspan="6">Data not found</td>
              </tr>
              <tr
                v-else
                v-for="(item, index) in adminList"
                :key="index"
                class="cursor-pointer"
                @click="getEdit(item.id)"
              >
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ formateDate(item.createdAt) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col md="12" sm="12" class="text-lg-right">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} dari {{ pages.end }}
        </span>
        <v-btn text class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn text class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <SnackBar :snackbar="isError" :msg="errorCause" @close="closeError" />
    <!-- add -->
    <v-dialog v-model="add" persistent max-width="500">
      <v-card class="rounded-3xl" style="border-radius: 10px">
        <v-card-title class="text-base font-bold">
          <span>{{ form.id === "" ? "Admin Baru" : "Detail Admin" }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="add = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <strong>Undang Admin Baru</strong>
          <p>
            Lengkapi Nama & Email admin baru, kami akan kirimkan berupa link
            undangan untuk bergabung.
          </p>
          <v-text-field
            class="pt-3"
            outlined
            dense
            label="Nama"
            type="text"
            v-model="form.name"
            :disabled="form.id !== ''"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            label="Email"
            :rules="emailRules"
            type="text"
            v-model="form.email"
            :disabled="form.id !== ''"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded text class="title-case" @click="add = false">
            Batal
          </v-btn>
          <v-btn
            v-if="form.id === ''"
            rounded
            class="title-case"
            :disabled="isFormValid"
            :dark="!isFormValid"
            :loading="loadingCreate"
            @click="save"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end add -->
    <Confirmation
      :show="confirmationModal.show"
      :title="confirmationModal.title"
      :msg="confirmationModal.msg"
      @close="closeConfirmation"
    />
  </div>
</template>
<script>
import { AdminControllers } from "@/controllers/AdminControllers";
import SnackBar from "@/views/Components/snackbar.vue";
import { dateToDateString } from "@/utils/helper.js";
import Confirmation from "@/views/Components/confirmation.vue";

export default {
  components: {
    SnackBar,
    Confirmation
  },
  data: () => ({
    admin: new AdminControllers(false, false, ""),

    meta: {
      page: 1,
      limit: 10,
      keyword: "",
    },

    add: false,
    showPass: false,
    form: {
      id: "",
      name: "",
      email: "",
      password: "",
    },
    emailRules: [(v) => /.+@.+\..+/.test(v) || "Format e-mail harus benar"],
    loadingCreate: false,
    isEdit: false,
    isDelete: false,
    confirmation: false,
    pages: {
      start: 1,
      end: 10,
    },
    confirmationModal: {
      show: false,
      title: "",
      msg: "",
    },
  }),
  computed: {
    adminList() {
      return this.admin.list;
    },
    isLoading() {
      return this.admin.loadingList;
    },
    isError() {
      return this.admin.errorList;
    },
    errorCause() {
      return this.admin.errorCauseList;
    },
    isFormValid() {
      if (this.form.name === "" || this.form.email === "") {
        return true;
      }

      return false;
    },
    isErrorCreate() {
      return this.admin.errorCreate;
    },
    errorCauseCreate() {
      return this.admin.errorCauseCreate;
    },
  },
  created() {
    this.getAdmin();
  },
  methods: {
    async getAdminList(page, limit, keyword) {
      return this.admin.getAdminList(page, limit, keyword);
    },
    async getAdmin() {
      this.filter = false;
      await this.getAdminList(
        this.meta.page,
        this.meta.limit,
        this.meta.keyword
      );
    },
    async formerPage() {
      if (this.pages.start > 10) {
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.meta.page -= 1;

        await this.getAdminList(
          this.meta.page,
          this.meta.limit,
          this.meta.keyword
        );
      }
    },
    async nextPage() {
      this.pages.start += 10;
      this.pages.end += 10;

      this.meta.page += 1;

      await this.getAdminList(
        this.meta.page,
        this.meta.limit,
        this.meta.keyword
      );
    },
    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.meta.page = 1;
      this.meta.limit = 10;

      await this.getAdminList(
        this.meta.page,
        this.meta.limit,
        this.meta.keyword
      );
    },
    debounceSearchEmpty(event) {
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.meta.page = 1;
          this.meta.limit = 10;

          await this.getAdminList(
            this.meta.page,
            this.meta.limit,
            this.meta.keyword
          );
        }
      }, 500);
    },
    closeError(status) {
      this.admin.setErrorList(status);
    },
    closeErrorCreate(status) {
      this.admin.setErrorCreate(status);
    },
    addNew() {
      this.isEdit = false;
      this.isDelete = false;
      this.add = true;
      this.form.id = "";
      this.form.name = "";
      this.form.email = "";
    },
    selectFile() {
      this.$refs.file.click();
    },
    async save() {
      this.loadingCreate = true;
      await this.admin.createAdmin({
        name: this.form.name,
        email: this.form.email,
      });
      if (!this.admin.errorCreate) {
        this.confirmationModal.show = true;
        this.confirmationModal.title = "sukses";
        this.confirmationModal.msg = "Pembuatan Admin Berhasil";
        this.getAdmin();
      } else {
        this.confirmationModal.show = true;
        this.confirmationModal.title = "gagal";
        this.confirmationModal.msg = "Pembuatan Admin ditolak";
      }
      this.loadingCreate = false;
      this.add = false;
    },
    closeConfirmation(status) {
      this.confirmationModal.show = status;
    },
    async getEdit(id) {
      this.add = true;
      this.isEdit = false;
      this.isDelete = false;
      await this.admin.getAdminById(id);
      this.form.id = this.admin.detail.id;
      this.form.name = this.admin.detail.name;
      this.form.email = this.admin.detail.email;
      this.form.password = this.admin.detail.password;
    },
    formateDate(date) {
      return dateToDateString(date);
    },
  },
};
</script>
<style scoped>
.title-case {
  text-transform: none;
}
.v-application p {
  margin-bottom: 0px;
}
</style>