<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="800">
        <v-card class="rounded-3xl">
          <v-card-title class="text-base font-bold pb-10">
            <span>Detail Usaha</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-card class="rounded-3xl">
              <v-card-title>
                <span class="text-base font-bold">Profil Usaha</span>
              </v-card-title>
              <v-card-text class="flex">
                <v-text-field
                  label="Nama Usaha"
                  class="mr-5"
                  v-model="data.business.name"
                  readonly
                ></v-text-field>
                <v-text-field
                  label="Jenis Usaha"
                  class="mr-5"
                  v-model="data.business.type"
                  readonly
                ></v-text-field>
                <v-textarea
                  label="Alamat"
                  class="mr-5"
                  v-model="data.business.address"
                  readonly
                ></v-textarea>
              </v-card-text>
            </v-card>

            <v-card class="rounded-3xl mt-5">
              <v-card-title>
                <span class="text-base font-bold">Profil Pemilik Usaha</span>
              </v-card-title>
              <v-card-text> ID: {{ data.id }}</v-card-text>
              <v-card-text class="flex">
                <v-text-field
                  label="Nama"
                  class="mr-5"
                  v-model="data.name"
                  readonly
                ></v-text-field>
              </v-card-text>
            </v-card>

            <v-card class="rounded-3xl mt-5">
              <v-card-title>
                <span class="text-base font-bold">Karyawan</span>
              </v-card-title>
              <v-card-text class="flex">
                <v-list-item two-line v-for="(item, index) in data.listStaff" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.email }}</v-list-item-title>
                    <v-list-item-subtitle class="text-gray-kc-200">{{ item.role }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <SnackBar :snackbar="isError" :msg="errorCause" @close="closeError" />
  </div>
</template>
<script>
import { BusinessController } from "@/controllers/BusinessController";
import { dateToDateString } from "@/utils/helper.js";
import SnackBar from "@/views/Components/snackbar.vue";

export default {
  data: () => ({
    business: new BusinessController(false, false, ""),
    dialog: false,
  }),
  computed: {
    data() {
      return {
        ...this.business.detail,
        business: this.business.detail.business ? {
          type: this.business.detail.business.type,
          name: this.business.detail.business.name,
          address: `${this.business.detail.business.address.street}, ${this.business.detail.business.address.village}, ${this.business.detail.business.address.district}, ${this.business.detail.business.address.city}, ${this.business.detail.business.address.province}`
        } : {
          type:"",
          name: "",
          address: ""
        }
      };
    },
    isLoading() {
      return this.business.loadingDetail;
    },
    isError() {
      return this.business.errorDetail;
    },
    errorCause() {
      return this.business.errorCauseDetail;
    },
  },
  components: {
    SnackBar
  },
  methods: {
    async getBusinessId(id) {
      return this.business.getBusinessById(id);
    },
    async openDialog(id) {
      this.dialog = true;
      await this.getBusinessId(id);
    },
    formateDate(date) {
      return dateToDateString(date);
    },
    closeError(status) {
      this.business.setErrorDetail(status);
    },
  },
};
</script>
<style scoped>
.v-sheet.v-card {
  border-radius: 10px;
}
</style>
