const SignIn = (role) => {
    const module = role === 'Owner' ? 'owner' : 'admin'
    return `/admins/v1/${module}/login`
}

const getTotal = (module, startDate, endDate, limit = "") => {
    return `/homes/v1/home/${module}/total?startDate=${startDate}&endDate=${endDate}&limit=${limit}`
}

const getProfile = (startDate, endDate) => {
    return `/profiles/v1/profile/total?startDate=${startDate}&endDate=${endDate}`
}

const getProfileList = (page, limit, startDate, endDate, status, sort, keyword) => {
    return `/profiles/v1/profile?page=${page}&size=${limit}&sort=${sort}&startDate=${startDate}&endDate=${endDate}&isActive=${status}&keyword=${keyword}`
}

const getProfileById = (id) => {
    return `/profiles/v1/profile/id/${id}`
}

const getTotalBuiness = (startDate, endDate) => {
    return `/business/v1/business/total?startDate=${startDate}&endDate=${endDate}`
}

const getBusinessList = (page, limit, startDate, endDate, sort, keyword) => {
    return `/business/v1/business?page=${page}&size=${limit}&sort=${sort}&startDate=${startDate}&endDate=${endDate}&keyword=${keyword}`
}

const getBusinessById = (id) => {
    return `/business/v1/business/id/${id}`
}

const getTotalTransaction = (startDate, endDate) => {
    return `/transactions/v1/transaction/total?startDate=${startDate}&endDate=${endDate}`
}

const getTransactionList = (page, limit, startDate, endDate, sort, keyword) => {
    return `/transactions/v1/transaction?page=${page}&size=${limit}&keyword=${keyword}&startDate=${startDate}&endDate=${endDate}&sort=${sort}`
}

const getTransactionById = (id) => {
    return `/transactions/v1/transaction/id/${id}`
}

const getSubscriptionList = (page, limit, startDate, endDate, sort, keyword) => {
    return `/subscriptions/v1/subscription?page=${page}&size=${limit}&keyword=${keyword}&startDate=${startDate}&endDate=${endDate}&sort=${sort}`
}

const getSubscriptionById = (id) => {
    return `/subscriptions/v1/subscription/id/${id}`
}

const createSubscription = () => {
    return `/subscriptions/v1/subscription`
}


const geVideoRecomendationList = (page, limit, startDate, endDate, sort, keyword) => {
    return `/recommendation-videos/v1/recommendation-video?page=${page}&size=${limit}&keyword=${keyword}&startDate=${startDate}&endDate=${endDate}&sort=${sort}`
}

const createVideo = () => {
    return `/recommendation-videos/v1/recommendation-video`
}

const getVideoById = (id) => {
    return `/recommendation-videos/v1/recommendation-video/id/${id}`
}

const updateVideo = (id) => {
    return `/recommendation-videos/v1/recommendation-video/id/${id}`
}

const deleteVideoById = (id) => {
    return `/recommendation-videos/v1/recommendation-video/id/${id}`
}

const geNotificationList = (page, limit, startDate, endDate, sort, keyword) => {
    return `/notifications/v1/notification?page=${page}&size=${limit}&keyword=${keyword}&startDate=${startDate}&endDate=${endDate}&sort=${sort}`
}

const createNotification = () => {
    return `/notifications/v1/notification`
}

const getNotificationById = (id) => {
    return `/notifications/v1/notification/id/${id}`
}

const updateNotifikasi = (id) => {
    return `/notifications/v1/notification/id/${id}`
}

const deleteNotifikasi = (id) => {
    return `/notifications/v1/notification/id/${id}`
}

const getFAQList = (page, limit, keyword) => {
    return `/faqs/v1/faq?page=${page}&size=${limit}&keyword=${keyword}`
}

const createFAQ = () => {
    return `/faqs/v1/faq`
}

const getFaqById = (id) => {
    return `/faqs/v1/faq/id/${id}`
}

const updateFaq = (id) => {
    return `/faqs/v1/faq/id/${id}`
}

const deleteFaqById = (id) => {
    return `/faqs/v1/faq/id/${id}`
}

const getAdminList = (page, limit, keyword) => {
    return `/admins/v1/admin?page=${page}&size=${limit}&keyword=${keyword}`
}

const createAdmin = () => {
    return `/admins/v1/admin`
}

const getAdminById = (id) => {
    return `/admins/v1/admin/id/${id}`
}

const uploadFile = () => {
    return `/uploads/v1/upload`
}

const getNotificationDashboardList = (page, limit) => {
    return `/dashboard-notifications/v1/dashboard-notification?page=${page}&size=${limit}`
}

const getReportAnalysis = (module, year, businessType) => {
    return `/report-analysis/v1/${module}?year=${year}&businessType=${businessType}`
}

module.exports = {
    SignIn,
    getTotal,
    getProfile,
    getProfileList,
    getProfileById,
    getTotalBuiness,
    getBusinessById,
    getBusinessList,
    getTotalTransaction,
    getTransactionList,
    getTransactionById,
    getSubscriptionList,
    getSubscriptionById,
    geVideoRecomendationList,
    createVideo,
    getVideoById,
    updateVideo,
    deleteVideoById,
    geNotificationList,
    getFAQList,
    createFAQ,
    getFaqById,
    updateFaq,
    deleteFaqById,
    getAdminList,
    createAdmin,
    getAdminById,
    uploadFile,
    createSubscription,
    createNotification,
    getNotificationById,
    updateNotifikasi,
    deleteNotifikasi,
    getNotificationDashboardList,
    getReportAnalysis
}