<template>
  <div id="app">
    <div v-if="!isLogin">
      <v-navigation-drawer
        flat
        v-model="drawer"
        enable-resize-watcher
        app
        class="mt-16"
      >
        <v-list dense nav>
          <v-subheader v-if="informationMenus.length > 0">INFORMASI</v-subheader>
          <div v-for="item in informationMenus" :key="item.title">
            <v-list-item link @click="goToPage(item.path)">
              <v-list-item-icon class="pr-8">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
        <v-list dense nav>
          <v-subheader v-if="settingMenus.length > 0">PENGATURAN</v-subheader>
          <div v-for="item in settingMenus" :key="item.title">
            <v-list-item link @click="goToPage(item.path)">
              <v-list-item-icon class="pr-8">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </v-navigation-drawer>

      <v-toolbar
        fixed
        flat
        class="toolbar"
        style="width: 100%; position: fixed; z-index: 1"
      >
        <v-icon @click.stop="drawer = !drawer">mdi-menu</v-icon>
        <v-toolbar-title>
          <div class="pl-10">
            <img :src="require(`@/assets/svg/logo.svg`)" />
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-toolbar-items
        class="d-flex"
        >
        
        <div class=" flex-column text-right">
          <v-list-item >
            <v-list-item-content >
              <v-list-item-title class="white--text">
                {{ getName }}
              </v-list-item-title>
              <v-list-item-subtitle class="white--text" >
                {{ getEmail }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div> 
        </v-toolbar-items>


        

        <div v-if="isLogoutVisible" class="relative mt-16 float-right">
          <div
            @click="logout"
            class="
              absolute
              bg-white-kc-100
              p-2
              shadow-md
              rounded-md
              cursor-pointer
            "
          >
            <div class="flex">
              <v-icon>mdi-logout</v-icon>
              Keluar
            </div>
          </div>
        </div>

        <v-btn icon>
          <v-icon @click="logoutShow"
            class="white--text">mdi-account-circle-outline mdi-48px</v-icon
          >
        </v-btn>

        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="400"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon class="white--text">mdi-bell-badge-outline</v-icon>
            </v-btn>
          </template>

          <v-card class="p-1">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-card flat>
                    <div v-for="(notif, index) in notifications" :key="index">
                      <v-card-title>
                        <span class="text-sm text-gray-kc-200">{{
                          notif.title
                        }}</span>
                        <v-spacer></v-spacer>
                        <div class="text-sm text-gray-kc-200">
                          {{ formateDate(notif.createdAt) }}
                        </div>
                      </v-card-title>
                      <v-card-text>
                        <strong>{{ notif.description }}</strong>
                      </v-card-text>
                      <v-divider></v-divider>
                    </div>
                    <div class="more">
                      <router-link :to="{ name: 'Notification Dashboard'}"> Lihat semua pemberitahuan</router-link>
                    </div>
                  </v-card>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar>

      <v-main>
        <v-app>
          <v-container fluid>
            <router-view></router-view>
          </v-container>
        </v-app>
      </v-main>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import { removeAuth, getName, getEmail, getRole } from "@/utils/cookies";
import { NotificationController } from "@/controllers/NotificationController";
import { dateToDateString } from "@/utils/helper.js";
import { defineInformationMenu, defineSettingsMenu } from "@/utils/menus.js"

const role = getRole()
export default {
  name: "Drawer",
  data: () => ({
    notifikasi: new NotificationController(false, false, ""),
    drawer: true,
    isLogoutVisible: false,
    fav: true,
    menu: false,
    message: false,
    hints: true,
    
  }),
  computed: {
    isLogin() {
      return this.$route.path === "/login";
    },
    getName() {
      return getName();
    },
    getEmail() {
      return getEmail();
    },
    notifications() {
      return this.notifikasi.listDashboard;
    },
    informationMenus() {
      return defineInformationMenu(role)
    },
    settingMenus() {
      return defineSettingsMenu(role)
    }
  },
  created() {
    this.getNotifikasi();
  },
  methods: {
    async getNotifikasi() {
      await this.notifikasi.getNotificationDashboardList(1, 5);
    },
    logoutShow() {
      this.isLogoutVisible = !this.isLogoutVisible;
    },
    logout() {
      removeAuth();
      this.$router.push({
        name: "Login",
      });
    },
    goToPage(path) {
      console.log(path);
      this.$router.push({
        name: path,
      });
    },
    formateDate(date) {
      return dateToDateString(date);
    },
  },
};
</script>
<style scoped>
.theme--light.v-navigation-drawer {
  background-color: #f0f0f0;
}

.toolbar {
  background-color: #2160AC !important;
  background-image: linear-gradient(90deg,#2160AC  0%, #388632,#D6DE23 100%) !important;
}
.toolbar-items{
  display: flex;
  flex-direction: column;
}
.white--text{
  color: #f0f0f0 !important;
}

/*.v-navigation-drawer--fixed {
  z-index: -1;
} */
.more {
  padding: 16px;
  font-size: 12px;
  z-index: auto;
}

.more a {
  text-decoration: none;
}

.more a:hover {
  text-decoration: underline;
}
</style>