<template>
  <v-row>
    <v-col :lg="`${role !== 'owner' ? '6' : '3'}`" sm="12">
      <v-card outlined class="mx-auto" max-width="100%">
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-sm font-bold mb-4">Penjualan</div>
            <v-list-item-title class="text-sm">
              {{ loading ? "Memuat..." : sales }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-avatar tile size="24">
            <span class="mdi mdi-point-of-sale mdi-24px"></span>
          </v-list-item-avatar>
        </v-list-item>
      </v-card>
    </v-col>

    <v-col lg="3" sm="12">
      <v-card outlined class="mx-auto" max-width="100%">
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-sm font-bold mb-4">Laba Kotor</div>
            <v-list-item-title class="text-sm">
              {{ loading ? "Memuat..." : grossProfit }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-avatar tile size="24">
            <span class="mdi mdi-cash-check mdi-24px"></span>
          </v-list-item-avatar>
        </v-list-item>
      </v-card>
    </v-col>

    <v-col lg="3" sm="12">
      <v-card outlined class="mx-auto" max-width="100%">
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-sm font-bold mb-4">Cash On Hand</div>
            <v-list-item-title class="text-sm">
              {{ loading ? "Memuat..." : cashOnHand }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-avatar tile size="24">
            <span class="mdi mdi-account-cash-outline mdi-24px"></span>
          </v-list-item-avatar>
        </v-list-item>
      </v-card>
    </v-col>

    <v-col lg="3" sm="12" v-if="role === 'owner'">
      <v-card outlined class="mx-auto" max-width="100%">
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-sm font-bold mb-4">HPP</div>
            <v-list-item-title class="text-sm">
              {{ loading ? "Memuat..." : hpp }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-avatar tile size="24">
            <span class="mdi mdi-label-percent mdi-24px"></span>
          </v-list-item-avatar>
        </v-list-item>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    loading: {
      default: false,
    },
    sales: {
      default: "0",
    },
    grossProfit: {
      default: "0",
    },
    cashOnHand: {
      default: "0",
    },
    role: {
      default: "",
    },
    hpp: {
      default: "0",
    },
  },
  data: () => ({}),
};
</script>
