<template>
  <div class="mx-5 my-20">
    <v-row>
      <v-col md="9" lg="9" sm="12">
        <p class="font-bold text-lg">FAQ</p>
      </v-col>
      <v-col md="3" lg="3" sm="12" class="text-lg-right">
        <v-btn rounded class="title-case" dark @click="addNew">
          <v-icon>mdi-note-plus mdi-18</v-icon>
          Tambah FAQ</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col md="9" lg="9" sm="12">
      </v-col>
      <v-col md="3" lg="3" sm="12">
        <v-text-field
          v-model="meta.keyword"
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          prepend-inner-icon="mdi-file-search-outline"
          placeholder="Cari FAQ"
          outlined
          rounded
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" lg="12" sm="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr class="bg-gray-kc-100">
                <th class="text-left">ID</th>
                <th class="text-left">Judul</th>
                <th class="text-left">Deskripsi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isLoading">
                <td colspan="6">Memuat...</td>
              </tr>
              <tr v-else-if="faqList.length === 0 && !isLoading">
                <td colspan="6">Data not found</td>
              </tr>
              <tr
                v-else
                v-for="(item, index) in faqList"
                :key="index"
                class="cursor-pointer"
                @click="getEdit(item.id)"
              >
                <td>{{ item.id }}</td>
                <td>{{ item.title }}</td>
                <td>{{ item.description }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col md="12" sm="12" class="text-lg-right">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} dari {{ pages.end }}
        </span>
        <v-btn text class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn text class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <SnackBar :snackbar="isError" :msg="errorCause" @close="closeError" />
    <!-- add -->
    <v-dialog v-model="add" persistent max-width="500">
      <v-card class="rounded-3xl" style="border-radius: 10px">
        <v-card-title class="text-base font-bold">
          <span>{{ form.id === "" ? "FAQ Baru" : "Edit FAQ" }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="add = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <strong>Judul & Deskripsi FAQ</strong>
          <v-text-field
            class="pt-3"
            outlined
            dense
            label="Judul FAQ"
            type="text"
            v-model="form.title"
          ></v-text-field>
          <v-textarea
            outlined
            dense
            label="Deskripsi FAQ"
            type="text"
            v-model="form.description"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="form.id === ''"
            rounded
            text
            class="title-case"
            @click="add = false"
          >
            Batal
          </v-btn>
          <v-btn
            v-else
            rounded
            text
            color="error"
            class="title-case"
            @click="deleteId()"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
            Hapus
          </v-btn>
          <v-btn
            rounded
            class="title-case"
            :disabled="isFormValid"
            :dark="!isFormValid"
            :loading="loadingCreate"
            @click="form.id === '' ? save() : update()"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end add -->

    <!-- delete -->
    <v-dialog v-model="confirmation" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Yakin akan hapus? </v-card-title>
        <v-card-text>
          Jika sudah terhapus maka data tidak akan kembali lagi
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text rounded @click="confirmation = false" class="title-case"> Kembali </v-btn>
          <v-btn dark rounded @click="goDelete" class="title-case" :loading="loadingCreate"> Hapus </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end delete -->

    <Confirmation
      :show="confirmationModal.show"
      :title="confirmationModal.title"
      :msg="confirmationModal.msg"
      @close="closeConfirmation"
    />
  </div>
</template>
<script>
import { FAQControllers } from "@/controllers/FAQControllers";
import SnackBar from "@/views/Components/snackbar.vue";
import Confirmation from "@/views/Components/confirmation.vue";

export default {
  components: {
    SnackBar,
    Confirmation
  },
  data: () => ({
    faq: new FAQControllers(false, false, ""),

    meta: {
      page: 1,
      limit: 10,
      keyword: ''
    },

    add: false,
    form: {
      id: "",
      title: "",
      description: "",
    },
    loadingCreate: false,
    isEdit: false,
    isDelete: false,
    confirmation: false,
    pages: {
      start: 1,
      end: 10,
    },
    confirmationModal: {
      show: false,
      title: "",
      msg: "",
    },
  }),
  computed: {
    faqList() {
      return this.faq.list;
    },
    isLoading() {
      return this.faq.loadingList;
    },
    isError() {
      return this.faq.errorList;
    },
    errorCause() {
      return this.faq.errorCauseList;
    },
    isFormValid() {
      if (
        this.form.title === "" ||
        this.form.description === ""
      ) {
        return true;
      }

      return false;
    },
    isErrorCreate() {
      return this.faq.errorCreate;
    },
    errorCauseCreate() {
      return this.faq.errorCauseCreate;
    },
  },
  created() {
    this.getFaq();
  },
  methods: {
    async getFaqList(page, limit, keyword) {
      return this.faq.getFAQList(
        page,
        limit,
        keyword
      );
    },
    async getFaq() {
      this.filter = false;
      await this.getFaqList(
        this.meta.page,
        this.meta.limit,
        this.meta.keyword
      );
    },
    async formerPage() {
      if (this.pages.start > 10) {
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.meta.page -= 1;

        await this.getFaqList(
          this.meta.page,
          this.meta.limit,
          this.meta.keyword
        );
      }
    },
    async nextPage() {
      this.pages.start += 10;
      this.pages.end += 10;

      this.meta.page += 1;

      await this.getFaqList(
        this.meta.page,
        this.meta.limit,
        this.meta.keyword
      );
    },
    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.meta.page = 1;
      this.meta.limit = 10;
      
      await this.getFaqList(
        this.meta.page,
        this.meta.limit,
        this.meta.keyword
      );
    },
    debounceSearchEmpty(event) {
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.meta.page = 1;
          this.meta.limit = 10;
          
          await this.getFaqList(
            this.meta.page,
            this.meta.limit,
            this.meta.keyword
          );
        }
      }, 500);
    },
    closeError(status) {
      this.faq.setErrorList(status);
    },
    closeErrorCreate(status) {
      this.faq.setErrorCreate(status);
    },
    addNew() {
      this.isEdit = false;
      this.isDelete = false;
      this.add = true;
      this.form.id = "";
      this.form.title = "";
      this.form.description = "";
    },
    selectFile() {
      this.$refs.file.click();
    },
    async save() {
      this.loadingCreate = true;
      await this.faq.createFAQ({
        title: this.form.title,
        description: this.form.description,
      });
      if (!this.faq.errorCreate) {
        this.confirmationModal.show = true;
        this.confirmationModal.title = "sukses";
        this.confirmationModal.msg = "Pembuatan FAQ Berhasil";
         this.getFaq();
      } else {
        this.confirmationModal.show = true;
        this.confirmationModal.title = "gagal";
        this.confirmationModal.msg = "Pembuatan FAQ ditolak";
      }
      this.loadingCreate = false;
      this.add = false;
    },
    closeConfirmation(status) {
      this.confirmationModal.show = status;
    },
    async getEdit(id) {
      this.add = true;
      this.isEdit = false;
      this.isDelete = false;
      await this.faq.getFaqById(id);
      this.form.id = this.faq.detail.id;
      this.form.title = this.faq.detail.title;
      this.form.description = this.faq.detail.description;
    },
    async update() {
      this.isEdit = true;
      this.loadingCreate = true;
      await this.faq.updateFaq(this.form.id, {
        title: this.form.title,
        description: this.form.description,
      });
      if (!this.faq.errorCreate) {
        this.confirmationModal.show = true;
        this.confirmationModal.title = "sukses";
        this.confirmationModal.msg = "Ubah FAQ Berhasil";
         this.getFaq();
      } else {
        this.confirmationModal.show = true;
        this.confirmationModal.title = "gagal";
        this.confirmationModal.msg = "Ubah FAQ ditolak";
      }
      this.loadingCreate = false;
      this.add = false;
    },
    deleteId() {
        this.confirmation = true;
    },
    async goDelete() {
      this.isDelete = true;
      this.loadingCreate = true;
      await this.faq.deleteFaqById(this.form.id);
      if (!this.faq.errorCreate) {
        this.confirmationModal.show = true;
        this.confirmationModal.title = "sukses";
        this.confirmationModal.msg = "Hapus FAQ Berhasil";
         this.getFaq();
      } else {
        this.confirmationModal.show = true;
        this.confirmationModal.title = "gagal";
        this.confirmationModal.msg = "Hapus FAQ ditolak";
      }
      this.confirmation = false
      this.loadingCreate = false;
      this.add = false;
    },
  },
};
</script>
<style scoped>
.title-case {
  text-transform: none;
}
.v-application p {
  margin-bottom: 0px;
}
</style>