import axios from 'axios'
import { geNotificationList, createNotification, getNotificationById, updateNotifikasi, deleteNotifikasi, getNotificationDashboardList } from '../endpoint/index'
import { BaseUrl } from '../endpoint/BaseUrl'
import { getToken } from '../../utils/cookies'

const headers = {
    'Authorization': `Bearer ${getToken()}`,
};

export const geNotificationListRequest = (page, limit, startDate, endDate, sort, keyword) => {
    let resp = null
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${geNotificationList(page, limit, startDate, endDate, sort, keyword)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const getNotificationByIdRequest = (id) => {
    let resp = null
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getNotificationById(id)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const createNotificationRequest = (data) => {
    let resp = null
    resp = axios({
        method: 'post',
        data: data,
        url: `${BaseUrl()}${createNotification()}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const updateNotifikasiRequest = (id, data) => {
    let resp = null
    resp = axios({
        method: 'put',
        data: data,
        url: `${BaseUrl()}${updateNotifikasi(id)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const deleteNotifikasiRequest = (id) => {
    let resp = null
    resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${deleteNotifikasi(id)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const getNotificationDashboardListRequest = (page, limit) => {
    let resp = null
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getNotificationDashboardList(page, limit)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}
