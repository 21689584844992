<template>
  <div class="mx-5 my-20">
    <v-row>
      <v-col md="9" lg="9" sm="12">
        <p class="font-bold text-lg">Berlangganan</p>
      </v-col>
      <v-col md="3" lg="3" sm="12" class="text-lg-right">
        <v-btn rounded class="title-case" dark @click="addNew">
          <v-icon>mdi-note-plus mdi-18</v-icon>
          Tambah Pelanggan</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col md="9" lg="9" sm="12" class="flex">
        <v-chip
          outlined
          class="mt-2 mr-2 cursor-pointer"
          @click="filter = true"
        >
          <v-icon>mdi-filter-menu-outline</v-icon> Filter
        </v-chip>
        <v-chip-group
          v-model="filterObject.quickDate"
          active-class="grey lighten-2 black--text"
          column
          @change="filterQuickDate"
        >
          <v-chip outlined>Minggu Ini</v-chip>
          <v-chip outlined>Bulan Ini</v-chip>
          <v-chip outlined>3 Bulan Terakhir</v-chip>
        </v-chip-group>
      </v-col>
      <v-col md="3" lg="3" sm="12">
        <v-text-field
          v-model="meta.keyword"
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          prepend-inner-icon="mdi-file-search-outline"
          placeholder="Cari Langganan"
          outlined
          rounded
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" lg="12" sm="12">
        Menampilkan daftar berlangganan <strong>{{ currentStatus }}</strong>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" lg="12" sm="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr class="bg-gray-kc-100">
                <th class="text-left">ID</th>
                <th class="text-left">Nama Pengguna</th>
                <th class="text-left">Tgl. Berlangganan</th>
                <th class="text-left">Dibuat Oleh</th>
                <th class="text-left">Download Invoice</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isLoading">
                <td colspan="6">Memuat...</td>
              </tr>
              <tr v-else-if="transactionList.length === 0 && !isLoading">
                <td colspan="6">Data not found</td>
              </tr>
              <tr
                v-else
                v-for="(item, index) in transactionList"
                :key="index"
                class="cursor-pointer"
                @click="getDetail(item.id)"
              >
                <td>{{ item.id }}</td>
                <td>{{ item.profile.name }}</td>
                <td>{{ formateDate(item.createdAt) }}</td>
                <td>{{ item.admin.name }}</td>
                <td>{{ item.invoice }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col md="12" sm="12" class="text-lg-right">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} dari {{ pages.end }}
        </span>
        <v-btn text class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn text class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <Detail ref="openDialog" />
    <!-- filter -->
    <v-dialog v-model="filter" persistent max-width="500">
      <v-card class="rounded-3xl" style="border-radius: 10px">
        <v-card-title class="text-base font-bold">
          <span>Filter</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="filter = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pl-2">
          <v-card flat>
            <v-card-title>
              <span class="text-sm font-bold">Urutkan</span>
            </v-card-title>
            <v-card-text class="flex">
              <v-chip-group
                v-model="filterObject.sort"
                active-class="grey lighten-2 black--text"
                column
              >
                <v-chip label outlined>A-Z</v-chip>
                <v-chip label outlined>Z-A</v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>
          <v-divider class="mx-4"></v-divider>
          <v-card flat>
            <v-card-title>
              <span class="text-sm font-bold">Tanggal Registrasi</span>
            </v-card-title>
            <v-card-text>
              <div class="flex">
                <v-menu
                  ref="menuDate1"
                  v-model="menuDate1"
                  :close-on-content-click="false"
                  :return-value.sync="startDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDate"
                      label="Dari Tanggal"
                      prepend-inner-icon="mdi-calendar"
                      dense
                      outlined
                      readonly
                      class="mr-1"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="startDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuDate1 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuDate1.save(startDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-menu
                  ref="menuDate2"
                  v-model="menuDate2"
                  :close-on-content-click="false"
                  :return-value.sync="endDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endDate"
                      label="Sampai Tanggal"
                      prepend-inner-icon="mdi-calendar"
                      class="ml-1"
                      dense
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="endDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuDate2 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuDate2.save(endDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
              <v-chip-group
                v-model="filterObject.quickDate"
                active-class="grey lighten-2 black--text"
                column
                @change="quickDatesFilter"
              >
                <v-chip outlined>Minggu ini</v-chip>
                <v-chip outlined>Bulan ini</v-chip>
                <v-chip outlined>3 Bulan terakhir</v-chip>
              </v-chip-group>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn rounded class="title-case" @click="filter = false">
                Batal
              </v-btn>
              <v-btn rounded class="title-case" dark @click="getTransaction">
                Terapkan
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end filter -->

    <!-- add -->
    <v-dialog v-model="add" persistent max-width="400">
      <v-card class="rounded-3xl" style="border-radius: 10px">
        <v-card-title class="text-base font-bold">
          <span>Pelanggan Baru</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="add = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <strong>Pilih Pengguna & Upload Bukti Transaksi </strong>
          <v-autocomplete
            class="pt-2"
            v-model="form.user"
            :items="users"
            item-text="name"
            item-value="id"
            label="Pilih Pengguna"
            required
            outlined
            :search-input.sync="searchUser"
            dense
            prepend-inner-icon="mdi-account-multiple-outline"
          ></v-autocomplete>
          <v-row class="pl-3">
            <v-col md="2" lg="2" sm="2" class="bg-gray-kc-100">
              <v-img
                v-if="!form.image"
                :src="require(`@/assets/svg/icon-img-upload.svg`)"
              />
              <v-img v-else :src="form.image" />
            </v-col>
            <v-col md="10" lg="10" sm="10">
              <p class="font-bold">Bukti Transaksi</p>
              <a class="font-bold" @click="selectFile">{{
                !form.image ? "Tambah Foto" : "Ganti Foto"
              }}</a>
              <input
                type="file"
                @change="onFileChange"
                ref="file"
                class="hidden"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded text class="title-case" @click="add = false">
            Batal
          </v-btn>
          <v-btn
            rounded
            class="title-case"
            @click="save"
            :loading="loadingCreate"
            :disabled="isFormValid"
            :dark="!isFormValid"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end add -->

    <Confirmation
      :show="confirmation.show"
      :title="confirmation.title"
      :msg="confirmation.msg"
      @close="closeConfirmation"
    />
  </div>
</template>
<script>
import Detail from "./components/Detail.vue";
import moment from "moment";
import { SubscriptionControllers } from "@/controllers/SubscriptionControllers";
import { ProfileControllers } from "@/controllers/ProfileControllers";
import { UploadControllers } from "@/controllers/UploadControllers";
import {
  dateToDateString,
  filterQuickDates,
  formatPrice,
} from "@/utils/helper.js";
import Confirmation from "@/views/Components/confirmation.vue";

export default {
  components: {
    Detail,
    Confirmation,
  },
  data: () => ({
    transaction: new SubscriptionControllers(false, false, ""),
    profile: new ProfileControllers(false, false, ""),
    upload: new UploadControllers(false, false, ""),
    filter: false,
    filterObject: {
      sort: 0,
      quickDate: 0,
    },

    // date
    startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    menuDate1: false,

    endDate: moment().format("YYYY-MM-DD"),
    menuDate2: false,
    meta: {
      page: 1,
      limit: 10,
      keyword: "",
    },

    pages: {
      start: 1,
      end: 10,
    },

    add: false,
    form: {
      user: "",
      file: null,
      image: null
    },
    search: {
      debounce: null,
    },
    searchUser: "",
    loadingCreate: false,
    confirmation: {
      show: false,
      title: "",
      msg: "",
    },
  }),
  computed: {
    users() {
      const data = [];
      this.profile.list.map((item) => {
        data.push({
          id: item.id,
          name: item.name,
        });
      });

      return data;
    },
    currentStatus() {
      if (this.filterObject.quickDate === 0) {
        return "Minggu ini";
      } else if (this.filterObject.quickDate === 1) {
        return "Bulan ini";
      } else {
        return "3 Bulan terakhir";
      }
    },
    transactionList() {
      return this.transaction.list;
    },
    isLoading() {
      return this.transaction.loadingList;
    },
    isError() {
      return this.transaction.errorList;
    },
    errorCause() {
      return this.transaction.errorCauseList;
    },
    isFormValid() {
      if (this.form.user === "" || this.form.file === null || this.form.image === null) {
        return true;
      }

      return false;
    },
  },
  watch: {
    searchUser(val = "") {
      if (val.length > 3) {
        return this.profile.getProfileList(1, 10, "", "", "", "asc", val);
      } 
    },
  },
  created() {
    this.getTransaction();
  },
  methods: {
    async getNotifikasiList(page, limit, startDate, endDate, sort, keyword) {
      return this.notifikasi.geNotificationList(
        page,
        limit,
        startDate,
        endDate,
        sort,
        keyword
      );
    },
    async getTransactionList(page, limit, startDate, endDate, sort, keyword) {
      return this.transaction.getSubscriptionList(
        page,
        limit,
        startDate,
        endDate,
        sort,
        keyword
      );
    },
    async getTransaction() {
      this.filter = false;
      await this.getTransactionList(
        this.meta.page,
        this.meta.limit,
        this.startDate,
        this.endDate,
        this.filterObject.sort === 0 ? "asc" : "desc",
        this.meta.keyword
      );
    },
    async formerPage() {
      if (this.pages.start > 10) {
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.meta.page -= 1;

        await this.getTransactionList(
          this.meta.page,
          this.meta.limit,
          this.startDate,
          this.endDate,
          this.filterObject.sort === 0 ? "asc" : "desc",
          this.meta.keyword
        );
      }
    },
    async nextPage() {
      this.pages.start += 10;
      this.pages.end += 10;

      this.meta.page += 1;

      await this.getTransactionList(
        this.meta.page,
        this.meta.limit,
        this.startDate,
        this.endDate,
        this.filterObject.sort === 0 ? "asc" : "desc",
        this.meta.keyword
      );
    },
    async filterQuickDate(e) {
      if (e !== undefined) {
        this.transaction.setSubscriptionList([]);

        const daterange = filterQuickDates(e);

        this.startDate = daterange.startDate;
        this.endDate = daterange.endDate;

        await this.getTransactionList(
          this.meta.page,
          this.meta.limit,
          daterange.startDate,
          daterange.endDate,
          this.filterObject.sort === 0 ? "asc" : "desc",
          this.meta.keyword
        );
      }
    },
    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.meta.page = 1;
      this.meta.limit = 10;

      await this.getTransactionList(
        this.meta.page,
        this.meta.limit,
        this.startDate,
        this.endDate,
        this.filterObject.sort === 0 ? "asc" : "desc",
        this.meta.keyword
      );
    },
    debounceSearchEmpty(event) {
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.meta.page = 1;
          this.meta.limit = 10;

          await this.getTransactionList(
            this.meta.page,
            this.meta.limit,
            this.startDate,
            this.endDate,
            this.filterObject.sort === 0 ? "asc" : "desc",
            this.meta.keyword
          );
        }
      }, 500);
    },
    closeError(status) {
      this.transaction.setErrorList(status);
    },
    formateDate(date) {
      return dateToDateString(date);
    },
    formateRupiah(value) {
      return formatPrice(value);
    },
    getDetail(id) {
      this.$refs.openDialog.openDialog(id);
    },
    quickDatesFilter(event) {
      if (event !== undefined) {
        const daterange = filterQuickDates(event);
        this.startDate = daterange.startDate;
        this.endDate = daterange.endDate;
      }
    },
    addNew() {
      this.add = true;
    },
    selectFile() {
      this.$refs.file.click();
    },
    onFileChange(e) {
      this.form.file = e.target.files[0];
      this.createBase64Image(e.target.files[0]);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);

      reader.onload = async (e) => {
        this.form.image = e.target.result;
      };
    },
    async save() {
      this.loadingCreate = true;
      await this.upload.uploadFile(this.form.file, "dashboard-subscription");
      if (this.upload.msgCreate) {
        await this.transaction.createSubscription({
          id: this.form.user,
          imageReceipt: this.upload.msgCreate,
        });

        if(!this.transaction.errorCreate) {
          this.msg(true, "sukses", "Pembuatan Pengguna Berlangganan berhasil")
          this.form.user = ""
          this.form.image = null
          this.getTransaction();
        } else {
          this.msg(true, "gagal", "Pembuatan Pengguna Berlangganan ditolak")
        }
      } else {
        this.msg(true, "gagal", "Upload file gagal")
      }

      this.add = false;
      this.loadingCreate = false;
    },
    msg(show, title, msg) {
      this.confirmation.show = show;
      this.confirmation.title = title;
      this.confirmation.msg = msg;
    },
    closeConfirmation(status) {
      this.confirmation.show = status;
    },
  },
};
</script>
<style scoped>
.title-case {
  text-transform: none;
}
.v-application p {
  margin-bottom: 0px;
}
</style>