import { GetProfileRequest, GetProfileListRequest, GetProfileByIdRequest } from '../api/request/ProfileRequest'

export class ProfileControllers {
    loading = false;
    error = false;
    errorCause = "";
    profile = {
        total: 0,
        active: 0,
        nonActive: 0
    }

    // list
    loadingList = false;
    errorList = false;
    errorCauseList = "";
    list = []
    //List ngambil meta page API
    metaList=[]

    // detail
    loadingDetail = false;
    errorDetail = false;
    errorCauseDetail = "";
    detail = {}

    constructor(loading, error, errorCause) {
        this.loading = loading;
        this.error = error;
        this.errorCause = errorCause;
    }

    getProfile(startDate, endDate) {
        this.setLoading(true)
        let resp = null;
        resp = GetProfileRequest(startDate, endDate)
            .then((response) => {
                this.setProfile(response.data.data)
                this.setErrorCause("")
                this.setError(false)
                return true
            }).catch((err) => {
                this.setProfile({
                    total: 0,
                    active: 0,
                    nonActive: 0
                })
                if (err.response.data.code === 404) {
                    this.setErrorCause('Data Tidak Ditemukan')
                    this.setError(false)
                } else {
                    this.setErrorCause(err)
                    this.setError(true)
                }
                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }

    getProfileList(page, limit, startDate, endDate, status, sort, keyword) {
        this.setLoadingList(true)
        let resp = null;
        resp = GetProfileListRequest(page, limit, startDate, endDate, status, sort, keyword)
            .then((response) => {
                this.setProfileList(response.data.data)
                //get meta page API
                this.setMetaList(response.data.meta)
                this.setErrorCauseList("")
                this.setErrorList(false)
                return true
            }).catch((err) => {
                this.setProfileList([])
                if (err.response.data.code === 404) {
                    this.setErrorCauseList('Data Tidak Ditemukan')
                    this.setErrorList(false)
                } else {
                    this.setErrorCauseList(err)
                    this.setErrorList(true)
                }
                return false
            }).finally(() => {
                this.setLoadingList(false)
            })
        return resp
    }

    getProfileById(id) {
        this.setLoadingDetail(true)
        let resp = null;
        resp = GetProfileByIdRequest(id)
            .then((response) => {
                this.setProfileDetail(response.data.data)
                this.setErrorCauseDetail("")
                this.setErrorDetail(false)
                return true
            }).catch((err) => {
                this.setProfileList([])
                if (err.response.data.code === 404) {
                    this.setErrorCauseDetail('Data Tidak Ditemukan')
                    this.setErrorDetail(false)
                } else {
                    this.setErrorCauseDetail(err)
                    this.setErrorDetail(true)
                }
                return false
            }).finally(() => {
                this.setLoadingDetail(false)
            })
        return resp
    }

    setLoading(status) {
        this.loading = status;
    }

    setLoadingList(status) {
        this.loadingList = status;
    }

    setProfileList(data) {
        this.list = data;
    }

    //set Meta page API
    setMetaList(meta){
        this.metaList=meta;
    }

    setErrorCauseList(err) {
        this.errorCauseList = err;
    }

    setErrorList(status) {
        this.errorList = status;
    }

    setProfile(data) {
        this.profile = data;
    }

    setError(status) {
        this.error = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setLoadingDetail(status) {
        this.loadingDetail = status;
    }

    setProfileDetail(data) {
        this.detail = data;
    }

    setErrorCauseDetail(err) {
        this.errorCauseDetail = err;
    }

    setErrorDetail(err) {
        this.errorDetail = err;
    }
}
