import axios from 'axios'
import { getAdminList, createAdmin, getAdminById } from '../endpoint/index'
import { BaseUrl } from '../endpoint/BaseUrl'
import { getToken } from '../../utils/cookies'

const headers = {
    'Authorization': `Bearer ${getToken()}`,
};

export const getAdminListRequest = (page, limit, keyword) => {
    let resp = null
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getAdminList(page, limit, keyword)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const getAdminByIdRequest = (id) => {
    let resp = null
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getAdminById(id)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const createAdminRequest = (data) => {
    let resp = null
    resp = axios({
        method: 'post',
        data: data,
        url: `${BaseUrl()}${createAdmin()}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

