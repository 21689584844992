import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from '@/plugins/vuetify' // path to vuetify export
import '@mdi/font/css/materialdesignicons.css'
import './index.css'

Vue.config.productionTip = false;
Vue.use({ iconfont: 'mdi' })

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
