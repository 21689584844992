import { AuthRequest } from '../api/request/AuthRequest'
import { setAuthentication } from '../utils/cookies'
export class AuthControllers {
    loading = false
    error = false
    errorCause = ''

    constructor(loading, error, errorCause) {
        this.loading = loading
        this.error = error
        this.errorCause = errorCause
    }

    signIn(email, password, role) {
        let resp = null
        this.setLoading(true)
        resp = AuthRequest(email, password, role)
            .then((response) => {
                this.setLoginDetail(response.data.data.token, response.data.data.admin.name, response.data.data.admin.email, response.data.data.admin.role)
                this.setErrorCause("")
                this.setError(false)
                return true
            }).catch((err) => {
                console.log(err)
                this.setErrorCause(err)
                this.setError(true)
                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }

    setLoginDetail(token, name, email, role) {
        const newRole = role ? role : 'owner'
        setAuthentication(token, name, email, newRole)
    }

    setLoading(status) {
        this.loading = status
    }

    setError(err) {
        this.error = err
    }

    setErrorCause(cause) {
        this.errorCause = cause
    }
}
