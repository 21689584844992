<template>
  <div class="mx-5 my-20">
    <v-row>
      <v-col md="12" lg="12" sm="12">
        <p class="font-bold text-base">Dashboard</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="9" lg="9" sm="12">
        <v-row>
          <v-col md="4" lg="4" sm="12">
            <TotalUsers :filters="filters" />
          </v-col>
          <v-col md="4" lg="4" sm="12">
            <TotalUsersActive :filters="filters" />
          </v-col>
          <v-col md="4" lg="4" sm="12">
            <TotalTransaction :filters="filters" />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" lg="12" sm="12">
            <v-card>
              <TotalSubscriber :filters="filters" />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="3" lg="3" sm="12">
        <Top :filters="filters" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TotalUsers from "./components/TotalUser.vue";
import TotalUsersActive from "./components/TotalUserActive.vue";
import TotalTransaction from "./components/TotalTransaction.vue";
import TotalSubscriber from "./components/TotalSubscriber.vue";
import Top from "./components/Top.vue";

export default {
  name: "Home",
  data: () => ({
    dateFiltered: 0,
    filters: [
      {
        id: 0,
        name: "Hari ini",
      },
      {
        id: 1,
        name: "Kemarin",
      },
      {
        id: 2,
        name: "Minggu ini",
      },
      {
        id: 3,
        name: "Bulan ini",
      },
    ],
  }),
  methods: {},
  components: {
    TotalUsers,
    TotalUsersActive,
    TotalTransaction,
    TotalSubscriber,
    Top,
  },
};
</script>
