<template>
  <v-col md="3" lg="3" sm="12">
    <v-card outlined class="mx-auto">
      <v-card-title class="text-sm font-bold"> Top UMKM </v-card-title>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-right">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="loading">
              <td colspan="2">Memuat...</td>
            </tr>
            <tr v-else-if="topBusiness.length === 0">
              <td colspan="2">Data tidak ada</td>
            </tr>
            <tr v-else v-for="(item, index) in topBusiness" :key="index">
              <td>{{ item.name }}</td>
              <td class="text-right">{{ formatPrice(item.totalAmount) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-card outlined class="mx-auto mt-2">
      <v-card-title class="text-sm font-bold"> Top Sales </v-card-title>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-right">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="loading">
              <td colspan="2">Memuat...</td>
            </tr>
            <tr v-else-if="topSales.length === 0">
              <td colspan="2">Data tidak ada</td>
            </tr>
            <tr v-else v-for="(item, index) in topSales" :key="index">
              <td>{{ item.name }}</td>
              <td class="text-right">{{ formatPrice(item.totalAmount) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-col>
</template>
<script>
import { formatThousand } from "@/utils/helper.js";
export default {
  props: {
    loading: {
      default: false,
    },
    topBusiness: {
      type: Array,
      default() {
        return [];
      },
    },
    topSales: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({
    formatPrice(val) {
      return formatThousand(val);
    },
  }),
};
</script>
