import { geVideoRecomendationListRequest, createVideoRequest, getVideoByIdRequest, updateVideoRequest, deleteVideoByIdRequest } from '../api/request/VideoRecomendationRequest'

export class VideoRecomendationControllers {
    // list
    loadingList = false;
    errorList = false;
    errorCauseList = "";
    list = []

    // create
    loadingCreate = false;
    msgCreate = "";
    errorCreate = false;
    errorCauseCreate = "";

    // detail
    loadingDetail = false;
    errorDetail = false;
    errorCauseDetail = "";
    detail = {}

    constructor(loading, error, errorCause) {
        this.loadingList = loading;
        this.errorList = error;
        this.errorCauseList = errorCause;
    }

    geVideoRecomendationList(page, limit, startDate, endDate, sort, keyword) {
        this.setLoadingList(true)
        let resp = null;
        resp = geVideoRecomendationListRequest(page, limit, startDate, endDate, sort, keyword)
            .then((response) => {
                this.setVideoList(response.data.data)
                this.setErrorCauseList("")
                this.setErrorList(false)
                return true
            }).catch((err) => {
                this.setVideoList([])
                if (err.response.data.code === 404) {
                    this.setErrorCauseList('Data Tidak Ditemukan')
                    this.setErrorList(false)
                } else {
                    this.setErrorCauseList(err)
                    this.setErrorList(true)
                }
                return false
            }).finally(() => {
                this.setLoadingList(false)
            })
        return resp
    }


    // list
    setLoadingList(status) {
        this.loadingList = status;
    }

    setVideoList(data) {
        this.list = data;
    }

    setErrorCauseList(err) {
        this.errorCauseList = err;
    }

    setErrorList(status) {
        this.errorList = status;
    }

    //create
    createVideo(data) {
        this.setLoadingCreate(true)
        let resp = null;
        resp = createVideoRequest(data)
            .then((response) => {
                this.setMsgSuccess(response.data.message)
                this.setErrorCauseCreate("")
                this.setErrorCreate(false)
                return true
            }).catch((err) => {
                this.setMsgSuccess("")
                this.setErrorCauseCreate(err)
                this.setErrorCreate(true)
                
                return false
            }).finally(() => {
                this.setLoadingCreate(false)
            })
        return resp
    }

    // update
    updateVideo(id, data) {
        this.setLoadingCreate(true)
        let resp = null;
        resp = updateVideoRequest(id, data)
            .then((response) => {
                this.setMsgSuccess(response.data.message)
                this.setErrorCauseCreate("")
                this.setErrorCreate(false)
                return true
            }).catch((err) => {
                this.setMsgSuccess("")
                this.setErrorCauseCreate(err)
                this.setErrorCreate(true)
                
                return false
            }).finally(() => {
                this.setLoadingCreate(false)
            })
        return resp
    }

    // delete
    deleteVideoById(id) {
        this.setLoadingCreate(true)
        let resp = null;
        resp = deleteVideoByIdRequest(id)
            .then((response) => {
                this.setMsgSuccess(response.data.message)
                this.setErrorCauseCreate("")
                this.setErrorCreate(false)
                return true
            }).catch((err) => {
                this.setMsgSuccess("")
                this.setErrorCauseCreate(err)
                this.setErrorCreate(true)
                
                return false
            }).finally(() => {
                this.setLoadingCreate(false)
            })
        return resp
    }

    setMsgSuccess(msg) {
        this.msgCreate = msg;
    }

    setLoadingCreate(status) {
        this.loadingCreate = status;
    }

    setErrorCauseCreate(err) {
        this.errorCauseCreate = err;
    }

    setErrorCreate(status) {
        this.errorCreate = status;
    }
    
    // detail
    getVideoById(id) {
        this.setLoadingDetail(true)
        let resp = null;
        resp = getVideoByIdRequest(id)
            .then((response) => {
                this.setTransactionDetail(response.data.data)
                this.setErrorCauseDetail("")
                this.setErrorDetail(false)
                return true
            }).catch((err) => {
                this.setTransactionDetail({})
                if (err.response.data.code === 404) {
                    this.setErrorCauseDetail('Data Tidak Ditemukan')
                    this.setErrorDetail(false)
                } else {
                    this.setErrorCauseDetail(err)
                    this.setErrorDetail(true)
                }
                return false
            }).finally(() => {
                this.setLoadingDetail(false)
            })
        return resp
    }

    //detail
    setLoadingDetail(status) {
        this.loadingDetail = status;
    }

    setTransactionDetail(data) {
        this.detail = data;
    }

    setErrorCauseDetail(err) {
        this.errorCauseDetail = err;
    }

    setErrorDetail(err) {
        this.errorDetail = err;
    }
}
