<template>
  <v-row>
    <v-col md="4" lg="4" sm="12">
      <v-card flat class="mx-auto p-2" max-width="100%">
        <v-card-title class="text-sm font-bold"
          >Total Berlangganan</v-card-title
        >
        <v-card-text>
          <v-select
            style="width: 60%; font-size: 10px; border-color: #ffffff"
            v-model="dateFiltered"
            :items="filters"
            @change="filterDate"
            item-text="name"
            item-value="id"
            flat
            dense
            full-width
          ></v-select>
          <div class="text-xs">
            {{ formateDate(dateRange.startDate) }} -
            {{ formateDate(dateRange.endDate) }}
          </div>
        </v-card-text>
        <v-card-actions>
          <div
            class="
              text-lg
              font-bold
              text-center
              p-2
              bg-gray-kc-100
              rounded-full
              w-full
            "
          >
            <span v-if="isLoading">Memuat...</span>
            <span v-else>{{ total }}</span>
          </div>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col md="8" lg="8" sm="12">
      <VueApexCharts
        type="area"
        width="90%"
        height="250"
        :options="chartOptions"
        :series="series"
      />
    </v-col>
    <SnackBar :snackbar="isError" :msg="errorCause" @close="closeError" />
  </v-row>
</template>
<script>
import { HomeControllers } from "@/controllers/HomeControllers";
import SnackBar from "@/views/Components/snackbar.vue";
import moment from "moment";
import { dateToDateString, filterDates } from "@/utils/helper.js";
import VueApexCharts from "vue-apexcharts";

export default {
  props: {
    filters: {
      default: [],
    },
  },
  components: {
    SnackBar,
    VueApexCharts,
  },
  data: () => ({
    home: new HomeControllers(false, false, ""),
    dateFiltered: 0,
    dateRange: {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
    total: 0,
    series: [],
    chartOptions: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: [],
      },
      tooltip: {},
    },
  }),
  computed: {
    isLoading() {
      return this.home.loading;
    },
    isError() {
      return this.home.error;
    },
    errorCause() {
      return this.home.errorCause;
    },
  },
  created() {
    this.getTotal();
  },
  methods: {
    async prosesTotal(module, startDate, endDate) {
      return this.home.getTotaActiveProfiles(3, module, startDate, endDate);
    },
    async getTotal() {
      await this.prosesTotal(
        "subscription",
        this.dateRange.startDate,
        this.dateRange.endDate
      );
      if (!this.isError) {
        this.total = this.home.totalsubscriber.total;
      }
    },
    closeError(status) {
      this.home.setError(status);
    },
    async filterDate(event) {
      const daterange = filterDates(event);

      this.dateRange.startDate = daterange.startDate;
      this.dateRange.endDate = daterange.endDate;

      await this.prosesTotal(
        "subscription",
        daterange.startDate,
        daterange.endDate
      );

      if (!this.isError) {
        this.total = this.home.totalsubscriber.total;
        const chartData = this.home.totalsubscriber.items.map((item) => item.total);
        this.series = [
          {
            name: 'series',
            data: chartData
          }
        ]
        this.chartOptions.xaxis.categories  = this.home.totalsubscriber.items.map(
          (item) => item.day
        );
      }
    },
    formateDate(date) {
      return dateToDateString(date);
    },
  },
};
</script>