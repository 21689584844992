<template>
  <v-row>
    <v-col md="12" lg="12" sm="12">
      <v-card>
        <v-row>
          <v-col md="3" lg="3" sm="12">
            <v-card class="mx-auto p-2" flat max-width="100%">
              <v-card-title class="text-base font-bold"
                >Total Berlangganan</v-card-title
              >
              <v-card-text>
                <v-select
                  style="width: 60%; font-size: 10px; border-color: #ffffff"
                  v-model="dateFiltered"
                  :items="filters"
                  @change="filterDate"
                  item-text="name"
                  item-value="id"
                  flat
                  dense
                  full-width
                ></v-select>
                <div class="text-xs">
                  {{ formateDate(dateRange.startDate) }} -
                  {{ formateDate(dateRange.endDate) }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-divider vertical class="my-3"></v-divider>
          <v-col md="3" lg="3" sm="12" class="text-center">
            <v-card class="mx-auto p-2 block" flat max-width="100%">
              <v-card-title class="text-base font-bold">Total</v-card-title>
              <v-card-text class="pt-10">
                <p v-if="isLoading">Memuat...</p>
                <h1 v-else class="title-count">{{ profiles.total }}</h1>
              </v-card-text>
            </v-card>
          </v-col>
          <v-divider vertical class="my-3"></v-divider>
          <v-col md="3" lg="3" sm="12" class="text-center">
            <v-card class="mx-auto p-2" flat max-width="100%">
              <v-card-title class="text-base font-bold">Aktif</v-card-title>
              <v-card-text class="pt-10">
                <p v-if="isLoading">Memuat...</p>
                <h1 v-else class="title-count">{{ profiles.active }}</h1>
              </v-card-text>
            </v-card>
          </v-col>
          <v-divider vertical class="my-3"></v-divider>
          <v-col md="3" lg="3" sm="12" class="text-center">
            <v-card class="mx-auto p-2" flat max-width="100%">
              <v-card-title class="text-base font-bold">Non Aktif</v-card-title>
              <v-card-text class="pt-10">
                <p v-if="isLoading">Memuat...</p>
                <h1 v-else class="title-count">{{ profiles.nonActive }}</h1>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <SnackBar :snackbar="isError" :msg="errorCause" @close="closeError" />
  </v-row>
</template>
<script>
import { ProfileControllers } from "@/controllers/ProfileControllers";
import SnackBar from "@/views/Components/snackbar.vue";
import moment from "moment";
import { dateToDateString, filterDates } from "@/utils/helper.js"

export default {
  components: {
    SnackBar,
  },
  data: () => ({
    profile: new ProfileControllers(false, false, ""),
    dateFiltered: 0,
    filters: [
      {
        id: 0,
        name: "Hari ini",
      },
      {
        id: 1,
        name: "Kemarin",
      },
      {
        id: 2,
        name: "Minggu ini",
      },
      {
        id: 3,
        name: "Bulan ini",
      },
    ],
    dateRange: {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
  }),
  computed: {
    profiles() {
      return this.profile.profile;
    },
    isLoading() {
      return this.profile.loading;
    },
    isError() {
      return this.profile.error;
    },
    errorCause() {
      return this.profile.errorCause;
    },
  },
  created() {
    this.getProfile();
  },
  methods: {
    async prosesProfile(startDate, endDate) {
      return this.profile.getProfile(startDate, endDate);
    },
    async getProfile() {
      await this.prosesProfile(
        this.dateRange.startDate,
        this.dateRange.endDate
      );
    },
    closeError(status) {
      this.profile.setError(status);
    },
    async filterDate(event) {
      const daterange = filterDates(event);

      this.dateRange.startDate = daterange.startDate;
      this.dateRange.endDate = daterange.endDate;

      await this.prosesProfile(daterange.startDate, daterange.endDate);
    },
    formateDate(date) {
      return dateToDateString(date);
    },
  },
};
</script>
<style scoped>
.v-card__title {
  align-items: center;
  display: inline;
  flex-wrap: wrap;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
}
.title-case {
  text-transform: none;
}
.title-count {
  font-weight: bold;
  font-size: 32px;
}
</style>