<template>
  <div class="mx-5 my-20">
    <v-row>
      <v-col md="9" lg="9" sm="12">
        <p class="font-bold text-lg">Notifikasi</p>
      </v-col>
      <v-col md="3" lg="3" sm="12" class="text-lg-right">
        <v-btn rounded class="title-case" dark @click="addNew">
          <v-icon>mdi-note-plus mdi-18</v-icon>
          Tambah Notifikasi</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col md="9" lg="9" sm="12" class="flex">
        <v-chip
          outlined
          class="mt-2 mr-2 cursor-pointer"
          @click="filter = true"
        >
          <v-icon>mdi-filter-menu-outline</v-icon> Filter
        </v-chip>
        <v-chip-group
          v-model="filterObject.quickDate"
          active-class="grey lighten-2 black--text"
          column
          @change="filterQuickDate"
        >
          <v-chip outlined>Minggu Ini</v-chip>
          <v-chip outlined>Bulan Ini</v-chip>
          <v-chip outlined>3 Bulan Terakhir</v-chip>
        </v-chip-group>
      </v-col>
      <v-col md="3" lg="3" sm="12">
        <v-text-field
          v-model="meta.keyword"
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          prepend-inner-icon="mdi-file-search-outline"
          placeholder="Cari Notifikasi"
          outlined
          rounded
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" lg="12" sm="12">
        Menampilkan daftar berlangganan <strong>{{ currentStatus }}</strong>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" lg="12" sm="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr class="bg-gray-kc-100">
                <th class="text-left">ID</th>
                <th class="text-left">Judul</th>
                <th class="text-left">Kategori</th>
                <th class="text-left">Tgl. Notifikasi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isLoading">
                <td colspan="6">Memuat...</td>
              </tr>
              <tr v-else-if="notifikasiList.length === 0 && !isLoading">
                <td colspan="6">Data not found</td>
              </tr>
              <tr
                v-else
                v-for="(item, index) in notifikasiList"
                :key="index"
                class="cursor-pointer"
                @click="getEdit(item.id)"
              >
                <td>{{ item.id }}</td>
                <td>{{ item.title }}</td>
                <td>{{ item.category }}</td>
                <td>{{ formateDate(item.createdAt) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col md="12" sm="12" class="text-lg-right">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} dari {{ pages.end }}
        </span>
        <v-btn text class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn text class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <SnackBar :snackbar="isError" :msg="errorCause" @close="closeError" />
    <!-- filter -->
    <v-dialog v-model="filter" persistent max-width="500">
      <v-card class="rounded-3xl" style="border-radius: 10px">
        <v-card-title class="text-base font-bold">
          <span>Filter</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="filter = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pl-2">
          <v-card flat>
            <v-card-title>
              <span class="text-sm font-bold">Urutkan</span>
            </v-card-title>
            <v-card-text class="flex">
              <v-chip-group
                v-model="filterObject.sort"
                active-class="grey lighten-2 black--text"
                column
              >
                <v-chip label outlined>A-Z</v-chip>
                <v-chip label outlined>Z-A</v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>
          <v-divider class="mx-4"></v-divider>
          <v-card flat>
            <v-card-title>
              <span class="text-sm font-bold">Tanggal Registrasi</span>
            </v-card-title>
            <v-card-text>
              <div class="flex">
                <v-menu
                  ref="menuDate1"
                  v-model="menuDate1"
                  :close-on-content-click="false"
                  :return-value.sync="startDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDate"
                      label="Dari Tanggal"
                      prepend-inner-icon="mdi-calendar"
                      dense
                      outlined
                      readonly
                      class="mr-1"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="startDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuDate1 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuDate1.save(startDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-menu
                  ref="menuDate2"
                  v-model="menuDate2"
                  :close-on-content-click="false"
                  :return-value.sync="endDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endDate"
                      label="Sampai Tanggal"
                      prepend-inner-icon="mdi-calendar"
                      class="ml-1"
                      dense
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="endDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuDate2 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuDate2.save(endDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
              <v-chip-group
                v-model="filterObject.quickDate"
                active-class="grey lighten-2 black--text"
                column
                @change="quickDatesFilter"
              >
                <v-chip outlined>Minggu ini</v-chip>
                <v-chip outlined>Bulan ini</v-chip>
                <v-chip outlined>3 Bulan terakhir</v-chip>
              </v-chip-group>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn rounded class="title-case" @click="filter = false">
                Batal
              </v-btn>
              <v-btn rounded class="title-case" dark @click="getNotifikasi">
                Terapkan
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end filter -->

    <!-- add -->
    <v-dialog v-model="add" persistent max-width="600">
      <v-card class="rounded-3xl" style="border-radius: 10px">
        <v-card-title class="text-base font-bold">
          <span>{{
            form.id === "" ? "Notifikasi Baru" : "Edit Notifikasi"
          }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="add = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-5">
          <v-select
            :items="optionsRecipent"
            label="Jenis Penerima"
            v-model="form.recipentType"
            dense
            outlined
            @change="selectRecipent"
          ></v-select>
          <v-combobox
            v-if="!isAllRecipent"
            v-model="form.recipient"
            :items="recipients"
            outlined
            dense
            chips
            small-chips
            label="Kepada"
            multiple
            item-text="name"
            item-value="id"
            :search-input.sync="searchUser"
          ></v-combobox>
          <v-select
            :items="categories"
            item-text="name"
            item-value="id"
            label="Kategori"
            v-model="form.category"
            dense
            outlined
          ></v-select>
          <strong>Masukkan Gambar, Judul & Deskripsi Notifikasi</strong>
          <div class="flex mt-5">
            <v-card flat outlined width="50%">
              <v-card-text>
                <v-row>
                  <v-col md="6" lg="6" sm="6" class="bg-gray-kc-100">
                    <v-img
                      v-if="!form.image"
                      :src="require(`@/assets/svg/icon-img-upload.svg`)"
                    />
                    <v-img v-else :src="form.image" />
                  </v-col>
                  <v-col md="6" lg="6" sm="6">
                    <p class="font-bold"></p>
                    <a class="font-bold" @click="selectFile">{{
                      !form.image ? "Tambah Gambar" : "Ganti Gambar"
                    }}</a>
                    <input type="file" @change="onFileChange" ref="file" class="hidden" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="p-5" flat width="50%">
              <strong>Catatan</strong>
              <p>
                Gambar yang digunakan akan menjadi gambar utama pada halaman
                notifikasi.
              </p>
            </v-card>
          </div>
          <v-text-field
            class="pt-3"
            outlined
            dense
            label="Judul Notifikasi"
            type="text"
            v-model="form.title"
          ></v-text-field>
          <v-textarea
            outlined
            dense
            label="Deskripsi Notifikasi"
            type="text"
            v-model="form.description"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="form.id === ''"
            rounded
            text
            class="title-case"
            @click="add = false"
          >
            Batal
          </v-btn>
          <v-btn
            v-else
            rounded
            text
            color="error"
            class="title-case"
            @click="deleteId()"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
            Hapus
          </v-btn>
          <v-btn
            rounded
            class="title-case"
            :disabled="isFormValid"
            :dark="!isFormValid"
            :loading="loadingCreate"
            @click="form.id === '' ? save() : update()"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end add -->

    <!-- delete -->
    <v-dialog v-model="confirmation" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Yakin akan hapus? </v-card-title>
        <v-card-text>
          Jika sudah terhapus maka data tidak akan kembali lagi
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text rounded @click="confirmation = false" class="title-case">
            Kembali
          </v-btn>
          <v-btn
            dark
            rounded
            @click="goDelete"
            class="title-case"
            :loading="loadingCreate"
          >
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end delete -->

    <Confirmation
      :show="confirmationModal.show"
      :title="confirmationModal.title"
      :msg="confirmationModal.msg"
      @close="closeConfirmation"
    />
  </div>
</template>
<script>
import moment from "moment";
import { NotificationController } from "@/controllers/NotificationController";
import { ProfileControllers } from "@/controllers/ProfileControllers";
import { UploadControllers } from "@/controllers/UploadControllers";
import {
  dateToDateString,
  filterQuickDates,
  formatPrice,
} from "@/utils/helper.js";
import SnackBar from "@/views/Components/snackbar.vue";
import Confirmation from "@/views/Components/confirmation.vue";

export default {
  components: {
    SnackBar,
    Confirmation
  },
  data: () => ({
    notifikasi: new NotificationController(false, false, ""),
    profile: new ProfileControllers(false, false, ""),
    upload: new UploadControllers(false, false, ""),
    filter: false,
    filterObject: {
      sort: 0,
      quickDate: 0,
    },

    // date
    startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    menuDate1: false,

    endDate: moment().format("YYYY-MM-DD"),
    menuDate2: false,
    meta: {
      page: 1,
      limit: 10,
      keyword: "",
    },

    pages: {
      start: 1,
      end: 10,
    },

    add: false,
    form: {
      id: "",
      recipentType: "",
      recipient: [],
      category: "",
      title: "",
      description: "",
      file: null,
      image: null
    },
    search: {
      debounce: null,
    },
    loadingCreate: false,
    isEdit: false,
    isDelete: false,
    confirmation: false,
    optionsRecipent: ["Semua Pengguna", "Pengguna Tertentu"],
    isAllRecipent: true,
    categories: [{
      id: 'info-umum',
      name: 'Info Umum'
    },{
      id: 'info-khusus',
      name: 'Info Khusus'
    }],
    confirmationModal: {
      show: false,
      title: "",
      msg: "",
    },
    searchUser: ""
  }),
  watch: {
    searchUser(val = "") {
      if (val.length > 3) {
        return this.profile.getProfileList(1, 10, "", "", "", "asc", val);
      } 
    },
  },
  computed: {
    recipients() {
      const data = [];
      this.profile.list.map((item) => {
        data.push({
          id: item.mobileNumber,
          name: item.name,
        });
      });

      return data;
    },
    currentStatus() {
      if (this.filterObject.quickDate === 0) {
        return "Minggu ini";
      } else if (this.filterObject.quickDate === 1) {
        return "Bulan ini";
      } else {
        return "3 Bulan terakhir";
      }
    },
    notifikasiList() {
      return this.notifikasi.list;
    },
    isLoading() {
      return this.notifikasi.loadingList;
    },
    isError() {
      return this.notifikasi.errorList;
    },
    errorCause() {
      return this.notifikasi.errorCauseList;
    },
    isFormValid() {
      if (
        this.form.category === "" ||
        this.form.image === null ||
        this.form.title === "" ||
        this.form.description === "" ||
        this.form.recipentType === ""
      ) {
        return true;
      }

      return false;
    },
    isErrorCreate() {
      return this.notifikasi.errorCreate;
    },
    errorCauseCreate() {
      return this.notifikasi.errorCauseCreate;
    },
  },
  created() {
    this.getNotifikasi();
    this.getProfiles();
  },
  methods: {
    async getProfiles() {
      await this.profile.getProfileList(1, 100, "", "", "", "asc");
    },
    async getNotifikasiList(page, limit, startDate, endDate, sort, keyword) {
      return this.notifikasi.geNotificationList(
        page,
        limit,
        startDate,
        endDate,
        sort,
        keyword
      );
    },
    async getNotifikasi() {
      this.filter = false;
      await this.getNotifikasiList(
        this.meta.page,
        this.meta.limit,
        this.startDate,
        this.endDate,
        this.filterObject.sort === 0 ? "asc" : "desc",
        this.meta.keyword
      );
    },
    async formerPage() {
      if (this.pages.start > 10) {
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.meta.page -= 1;

        await this.getNotifikasiList(
          this.meta.page,
          this.meta.limit,
          this.startDate,
          this.endDate,
          this.filterObject.sort === 0 ? "asc" : "desc",
          this.meta.keyword
        );
      }
    },
    async nextPage() {
      this.pages.start += 10;
      this.pages.end += 10;

      this.meta.page += 1;

      await this.getNotifikasiList(
        this.meta.page,
        this.meta.limit,
        this.startDate,
        this.endDate,
        this.filterObject.sort === 0 ? "asc" : "desc",
        this.meta.keyword
      );
    },
    async filterQuickDate(e) {
      if (e !== undefined) {
        this.notifikasi.setNotificationList([]);

        const daterange = filterQuickDates(e);

        this.startDate = daterange.startDate;
        this.endDate = daterange.endDate;

        await this.getNotifikasiList(
          this.meta.page,
          this.meta.limit,
          daterange.startDate,
          daterange.endDate,
          this.filterObject.sort === 0 ? "asc" : "desc",
          this.meta.keyword
        );
      }
    },
    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.meta.page = 1;
      this.meta.limit = 10;

      await this.getNotifikasiList(
        this.meta.page,
        this.meta.limit,
        this.startDate,
        this.endDate,
        this.filterObject.sort === 0 ? "asc" : "desc",
        this.meta.keyword
      );
    },
    debounceSearchEmpty(event) {
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.meta.page = 1;
          this.meta.limit = 10;

          await this.getNotifikasiList(
            this.meta.page,
            this.meta.limit,
            this.startDate,
            this.endDate,
            this.filterObject.sort === 0 ? "asc" : "desc",
            this.meta.keyword
          );
        }
      }, 500);
    },
    closeError(status) {
      this.notifikasi.setErrorList(status);
    },
    closeErrorCreate(status) {
      this.notifikasi.setErrorCreate(status);
    },
    formateDate(date) {
      return dateToDateString(date);
    },
    formateRupiah(value) {
      return formatPrice(value);
    },
    getDetail(id) {
      this.$refs.openDialog.openDialog(id);
    },
    quickDatesFilter(event) {
      if (event !== undefined) {
        const daterange = filterQuickDates(event);
        this.startDate = daterange.startDate;
        this.endDate = daterange.endDate;
      }
    },
    addNew() {
      this.isEdit = false;
      this.isDelete = false;
      this.add = true;

      this.form.id = ""
      this.form.category = ""
      this.form.image = null
      this.form.title = ""
      this.form.description = ""
      this.form.recipentType = []
      this.form.file = null
    },
    selectFile() {
      this.$refs.file.click();
    },
    onFileChange(e) {
      this.form.file = e.target.files[0];
      this.createBase64Image(e.target.files[0]);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);

      reader.onload = async (e) => {
        this.form.image = e.target.result;
      };
    },
    async save() {
      this.loadingCreate = true;
      await this.upload.uploadFile(this.form.file, "dashboard-subscription");
      if (this.upload.msgCreate) {
        await this.notifikasi.createNotification({
          category: this.form.category,
          image: this.upload.msgCreate,
          title: this.form.title,
          description: this.form.description,
          option: {
            target: this.form.recipentType === "Semua Pengguna" ? "all" : "part",
            list: this.form.recipient.map(item => item.id)
          }
        });

        if(!this.notifikasi.errorCreate) {
          this.msg(true, "sukses", "Pembuatan Notifikasi berhasil")
          this.form.category = ""
          this.form.image = null
          this.form.title = ""
          this.form.description = ""
          this.form.recipient = []
          this.form.recipentType = "Semua Pengguna"

          this.getNotifikasi();
        } else {
          this.msg(true, "gagal", "Pembuatan Notifikasi ditolak")
        }
      } else {
        this.msg(true, "gagal", "Upload file gagal")
      }

      this.add = false;
      this.loadingCreate = false;
    },
    msg(show, title, msg) {
      this.confirmationModal.show = show;
      this.confirmationModal.title = title;
      this.confirmationModal.msg = msg;
    },
    closeConfirmation(status) {
      this.confirmationModal.show = status;
    },
    async getEdit(id) {
      this.add = true;
      this.isEdit = false;
      this.isDelete = false;
      await this.notifikasi.getNotificationById(id);

      this.form.id = this.notifikasi.detail.id
      this.form.category = this.notifikasi.detail.category
      this.form.image = this.notifikasi.detail.image 
      this.form.title = this.notifikasi.detail.title
      this.form.description = this.notifikasi.detail.description
      this.form.recipentType = this.notifikasi.detail.option === 'all' ? 'Semua Pengguna' : 'Pengguna Tertentu'
      this.form.file = null
    },
    async update() {
      this.isEdit = true;
      this.loadingCreate = true;
      const option = {
        target: this.form.recipentType === "Semua Pengguna" ? "all" : "part",
        list: this.form.recipentType === "Semua Pengguna" ? [] : this.form.recipient.map(item => item.id)
      }
      if(this.form.file) {
        await this.upload.uploadFile(this.form.file, "dashboard-subscription");
        if (this.upload.msgCreate) {
          this.updateProcess(
            this.form.category,
            this.upload.msgCreate,
            this.form.title,
            this.form.description,
            option
          )
        } else {
          this.msg(true, "gagal", "Upload file gagal")
        }
      } else {
        this.updateProcess(
          this.form.category,
          this.notifikasi.detail.image,
          this.form.title,
          this.form.description,
          option
        )
      }
      this.loadingCreate = false;
      this.add = false;
    },
    async updateProcess(category, image, title, description, recipentType, recipient) {
      await this.notifikasi.updateNotifikasi(this.form.id, {
          category: category,
          image: image,
          title: title,
          description: description,
          option: {
            target: recipentType,
            list: recipient
          }
        });

        if(!this.notifikasi.errorCreate) {
          this.msg(true, "sukses", "Perubahan Notifikasi berhasil")
          this.getNotifikasi();
        } else {
          this.msg(true, "gagal", "Perubahan Notifikasi ditolak")
        }
    },
    deleteId() {
      this.confirmation = true;
    },
    async goDelete() {
      this.isDelete = true;
      this.loadingCreate = true;
      await this.notifikasi.deleteNotifikasi(this.form.id);
      this.confirmation = false;
      this.loadingCreate = false;
      this.add = false;
      this.getNotifikasi();
    },
    selectRecipent(e) {
      if (e === "Semua Pengguna") {
        this.isAllRecipent = true;
      } else {
        this.isAllRecipent = false;
      }
    },
  },
};
</script>
<style scoped>
.title-case {
  text-transform: none;
}
.v-application p {
  margin-bottom: 0px;
}
</style>