<template>
  <v-row>
    <v-col md="6" lg="6" sm="12">
      <v-card>
        <v-row>
          <v-col md="5" lg="5" sm="12">
            <v-card class="mx-auto p-2" flat max-width="100%">
              <v-card-title class="text-base font-bold"
                >Total Transaksi</v-card-title
              >
              <v-card-text>
                <v-select
                  style="width: 60%; font-size: 10px; border-color: #ffffff"
                  v-model="dateFiltered"
                  :items="filters"
                  @change="filterDate"
                  item-text="name"
                  item-value="id"
                  flat
                  dense
                  full-width
                ></v-select>
                <div class="text-xs">{{ formateDate(dateRange.startDate) }} - {{ formateDate(dateRange.endDate) }}</div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="7" lg="7" sm="12" class="text-center">
            <v-card class="mx-auto p-2 block" flat max-width="100%">
              <v-card-text class="pt-20">
                <p v-if="isLoading">Memuat...</p>
                <h1 v-else class="title-count">{{ formateRupiah(total) }}</h1>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <SnackBar :snackbar="isError" :msg="errorCause" @close="closeError" />
  </v-row>
</template>
<script>
import { TransactionController } from "@/controllers/TransactionController";
import SnackBar from "@/views/Components/snackbar.vue";
import moment from "moment";
import { dateToDateString, formatPrice, filterQuickDates } from "@/utils/helper.js";
export default {
  components: {
    SnackBar,
  },
  data: () => ({
    transction: new TransactionController(false, false, ""),
    dateFiltered: 0,
    filters: [
      {
        id: 0,
        name: "Minggu ini",
      },
      {
        id: 1,
        name: "Bulan ini",
      },
      {
        id: 2,
        name: "3 Bulan terakhir",
      },
    ],
    dateRange: {
      startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      endDate: moment().format("YYYY-MM-DD"),
    },
  }),
  computed: {
    total() {
      return this.transction.transactionData;
    },
    isLoading() {
      return this.transction.loading;
    },
    isError() {
      return this.transction.error;
    },
    errorCause() {
      return this.transction.errorCause;
    },
  },
  created() {
    this.getTransaction();
  },
  methods: {
    async prosesTransaction(startDate, endDate) {
      return this.transction.getTotalTransaction(startDate, endDate);
    },
    async getTransaction() {
      await this.prosesTransaction(
        this.dateRange.startDate,
        this.dateRange.endDate
      );
    },
    closeError(status) {
      this.business.setError(status);
    },
    formateDate(date) {
      return dateToDateString(date);
    },
    formateRupiah(value) {
      return formatPrice(value);
    },
    async filterDate(event) {
      this.tops = []
      const daterange = filterQuickDates(event);

      this.dateRange.startDate = daterange.startDate;
      this.dateRange.endDate = daterange.endDate;

      await this.prosesTransaction(
        daterange.startDate,
        daterange.endDate
      );
    },
  }
};
</script>
<style scoped>
.v-card__title {
  align-items: center;
  display: inline;
  flex-wrap: wrap;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
}
.title-case {
  text-transform: none;
}
.title-count {
  font-weight: bold;
  font-size: 20px;
}
</style>