export const defineInformationMenu = (role) => {
    const menusStored = {
        "thirdparty": [
            {
                title: "Laporan Analisis",
                icon: "mdi-file",
                path: "Laporan Analisis",
            },
        ],
        "owner": [
            {
                title: "Laporan Analisis",
                icon: "mdi-file",
                path: "Laporan Analisis",
            },
        ],
        "admin": [
            { title: "Dashboard", icon: "mdi-view-dashboard", path: "Home" },
            {
                title: "Pengguna",
                icon: "mdi-account-supervisor-circle",
                path: "Users",
            },
            { title: "Usaha", icon: "mdi-chart-waterfall", path: "Usaha" },
            {
                title: "Transaksi",
                icon: "mdi-chart-timeline-variant-shimmer",
                path: "Transaksi",
            },
            {
                title: "Berlangganan",
                icon: "mdi-bell-circle-outline",
                path: "Subscription",
            },
            {
                title: "Laporan Analisis",
                icon: "mdi-file",
                path: "Laporan Analisis",
            },
        ],
        "": []
    }

    return menusStored[role];
}

export const defineSettingsMenu = (role) => {
    const menusStored = {
        "thirdparty": [],
        "owner": [],
        "admin": [
            { title: "Video Rekomendasi", icon: "mdi-animation-play", path: "Video" },
            { title: "Notifikasi", icon: "mdi-speaker", path: "Notification" },
            { title: "FAQ", icon: "mdi-frequently-asked-questions", path: "FAQ" },
            { title: "Admin", icon: "mdi-account", path: "Admin" },
        ],
        "": []
    }

    return menusStored[role];
}