import Cookies from 'js-cookie'

export const setAuthentication = (token, name, email, role) => {
    Cookies.set('kc_access_token', token)
    Cookies.set('kc_name', name)
    Cookies.set('kc_email', email)
    Cookies.set('kc_role', role)
}


export const getToken = () => Cookies.get('kc_access_token') || ''
export const getName = () => Cookies.get('kc_name') || ''
export const getEmail = () => Cookies.get('kc_email') || ''
export const getRole = () => Cookies.get('kc_role') || ''

export const removeAuth = () => {
    Cookies.remove('kc_access_token')
}