<template>
  <div>
    <v-card class="mx-auto p-2" max-width="100%">
      <v-card-title class="text-sm  font-bold">Total Pengguna Non-Aktif</v-card-title>
      <v-card-text>
        <v-select
          style="width: 60%; font-size: 10px; border-color: #ffffff"
          v-model="dateFiltered"
           @change="filterDate"
          :items="filters"
          item-text="name"
          item-value="id"
          flat
          dense
          full-width
        ></v-select>
        <div class="text-xs">{{ formateDate(dateRange.startDate) }} - {{ formateDate(dateRange.endDate) }}</div>
      </v-card-text>
      <v-card-actions>
        <div
          class="
            text-lg
            font-bold
            text-center
            p-2
            bg-gray-kc-100
            rounded-full
            w-full
          "
        >
        <span v-if="isLoading">Memuat...</span>
        <span v-else>{{ total }}</span>
        </div>
      </v-card-actions>
    </v-card>
    <SnackBar :snackbar="isError" :msg="errorCause" @close="closeError" />
  </div>
</template>
<script>
import { HomeControllers } from "@/controllers/HomeControllers";
import SnackBar from "@/views/Components/snackbar.vue"
import moment from 'moment';
import { dateToDateString, filterDates } from "@/utils/helper.js"
export default {
  props: {
    filters: {
      default: [],
    },
  },
  components: {
    SnackBar
  },
  data: () => ({
    home: new HomeControllers(false, false, ""),
    dateFiltered: 0,
    dateRange: {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD')
    }
  }),
  computed: {
    total() {
      return this.home.totalNonActiveUser
    },
    isLoading() {
      return this.home.loading
    },
    isError() {
      return this.home.error
    },
    errorCause() {
      return this.home.errorCause
    }
  },
  created() {
    this.getTotal()
  },
  methods: {
    async prosesTotal(module, startDate, endDate) {
      return this.home.getTotaActiveProfiles(1, module, startDate, endDate)
    },
    async getTotal() {
      await this.prosesTotal(
        'profile-non-active',
        this.dateRange.startDate,
        this.dateRange.endDate
      )
    },
    closeError(status) {
      this.home.setError(status);
    },
    async filterDate(event) {
      const daterange = filterDates(event)
      
      this.dateRange.startDate = daterange.startDate;
      this.dateRange.endDate = daterange.endDate;

      await this.prosesTotal(
        'profile-non-active',
        daterange.startDate,
        daterange.endDate
      )
    },
    formateDate(date) {
      return dateToDateString(date)
    }
  }
};
</script>