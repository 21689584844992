import axios from 'axios'
import { getTotal } from '../endpoint/index'
import { BaseUrl } from '../endpoint/BaseUrl'
import { getToken } from '../../utils/cookies'

const headers = {
    'Authorization': `Bearer ${getToken()}`,
};

export const GetTotalRequest = (module, startDate, endDate, limit) => {
    let resp = null
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getTotal(module, startDate, endDate, limit)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}
