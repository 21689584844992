import Vue from "vue";
import VueRouter from "vue-router";
import { getToken, removeAuth, getRole } from "@/utils/cookies";
import { defineRole } from "@/utils/roles.js"
import Login from "../views/Login";
import Home from "../views/Home";
import Users from "../views/Users";
import Usaha from "../views/Usaha";
import Transaksi from "../views/Transaksi";
import Subscription from "../views/Subscription"
import Video from "../views/Video"
import Notification from "../views/Notification"
import LaporanAnalisis from "../views/LaporanAnalisis"
import FAQ from "../views/FAQ"
import Admin from "../views/Admin"
import NotificationDashboard from "../views/NotificationDashboard"
import NotFound from "../views/NotFound"

Vue.use(VueRouter);

const role = getRole();

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Home",
    component: getRole() === 'admin' ? Home : LaporanAnalisis,
  },
  {
    path: "/pengguna",
    name: "Users",
    component: Users,
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'users');
      if (!access.read) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: "/usaha",
    name: "Usaha",
    component: Usaha,
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'usaha');
      if (!access.read) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: "/transaksi",
    name: "Transaksi",
    component: Transaksi,
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'transaksi');
      if (!access.read) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: "/subscription",
    name: "Subscription",
    component: Subscription,
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'subscription');
      if (!access.read) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: "/video",
    name: "Video",
    component: Video,
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'videoRekomendasi');
      if (!access.read) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: "/notification",
    name: "Notification",
    component: Notification,
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'notification');
      if (!access.read) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: "/laporan-analisis",
    name: "Laporan Analisis",
    component: LaporanAnalisis,
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'analysisReport');
      if (!access.read) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'faq');
      if (!access.read) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'admin');
      if (!access.read) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: "/notification-dashboard",
    name: "Notification Dashboard",
    component: NotificationDashboard,
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'dashboard');
      if (!access.read) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: "/not-found",
    name: "Not Found",
    component: NotFound,
  }
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  function checkAuth() {
    const token = getToken();
    return token
  }

  const isAuth = checkAuth();

  if (to.name !== "Login" && !isAuth) {
    removeAuth();
    next({ name: 'Login' });
    return;
  }

  if (to.name === "Login" && isAuth) {
    next({ name: "Home" });
    return;
  }

  next()

});

export default router;
