import { getTotalTransactionRequest, getTransactionListRequest, getTransactionByIdRequest } from '../api/request/TransactionRequest'

export class TransactionController {
    loading = false;
    error = false;
    errorCause = "";
    transactionData = 0;

    // list
    loadingList = false;
    errorList = false;
    errorCauseList = "";
    list = []
    //List ngambil meta page API
    metaList=[]
     // detail
     loadingDetail = false;
     errorDetail = false;
     errorCauseDetail = "";
     detail = {}
 


    constructor(loading, error, errorCause) {
        this.loading = loading;
        this.error = error;
        this.errorCause = errorCause;
    }

    getTotalTransaction(startDate, endDate) {
        this.setLoading(true)
        let resp = null;
        resp = getTotalTransactionRequest(startDate, endDate)
            .then((response) => {
                this.setTransaction(response.data.data[0].total)
                this.setErrorCause("")
                this.setError(false)
                return true
            }).catch((err) => {
                
                if (err.response.data.code === 404) {
                    this.setErrorCause('Data Tidak Ditemukan')
                    this.setError(false)
                } else {
                    this.setErrorCause(err)
                    this.setError(true)
                }
                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }

    getTransactionList(page, limit, startDate, endDate, sort, keyword) {
        this.setLoadingList(true)
        let resp = null;
        resp = getTransactionListRequest(page, limit, startDate, endDate, sort, keyword)
            .then((response) => {
                this.setTransactionList(response.data.data)
                //get meta page API
                this.setMetaList(response.data.meta)
                this.setErrorCauseList("")
                this.setErrorList(false)
                return true
            }).catch((err) => {
                this.setTransactionList([])
                if (err.response.data.code === 404) {
                    this.setErrorCauseList('Data Tidak Ditemukan')
                    this.setErrorList(false)
                } else {
                    this.setErrorCauseList(err)
                    this.setErrorList(true)
                }
                return false
            }).finally(() => {
                this.setLoadingList(false)
            })
        return resp
    }

    getTransactionById(id) {
        this.setLoadingDetail(true)
        let resp = null;
        resp = getTransactionByIdRequest(id)
            .then((response) => {
                this.setTransactionDetail(response.data.data)
                this.setErrorCauseDetail("")
                this.setErrorDetail(false)
                return true
            }).catch((err) => {
                this.setTransactionDetail({})
                if (err.response.data.code === 404) {
                    this.setErrorCauseDetail('Data Tidak Ditemukan')
                    this.setErrorDetail(false)
                } else {
                    this.setErrorCauseDetail(err)
                    this.setErrorDetail(true)
                }
                return false
            }).finally(() => {
                this.setLoadingDetail(false)
            })
        return resp
    }


    setTransaction(data) {
        this.transactionData = data;
    }

    setLoading(status) {
        this.loading = status;
    }

    setError(status) {
        this.error = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    // list
    setLoadingList(status) {
        this.loadingList = status;
    }
    //set Meta page API
    setMetaList(meta){
        this.metaList=meta;
    }
    setTransactionList(data) {
        this.list = data;
    }

    setErrorCauseList(err) {
        this.errorCauseList = err;
    }

    setErrorList(status) {
        this.errorList = status;
    }

    //detail
    setLoadingDetail(status) {
        this.loadingDetail = status;
    }

    setTransactionDetail(data) {
        this.detail = data;
    }

    setErrorCauseDetail(err) {
        this.errorCauseDetail = err;
    }

    setErrorDetail(err) {
        this.errorDetail = err;
    }
}
