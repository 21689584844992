import axios from 'axios'
import { getProfile, getProfileList, getProfileById } from '../endpoint/index'
import { BaseUrl } from '../endpoint/BaseUrl'
import { getToken } from '../../utils/cookies'

const headers = {
    'Authorization': `Bearer ${getToken()}`,
};

export const GetProfileRequest = (startDate, endDate) => {
    let resp = null
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getProfile(startDate, endDate)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const GetProfileListRequest = (page, limit, startDate, endDate, status, sort, keyword) => {
    let resp = null
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getProfileList(page, limit, startDate, endDate, status, sort, keyword)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const GetProfileByIdRequest = (id) => {
    let resp = null
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getProfileById(id)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}