import axios from 'axios'
import { geVideoRecomendationList, createVideo, getVideoById, updateVideo, deleteVideoById } from '../endpoint/index'
import { BaseUrl } from '../endpoint/BaseUrl'
import { getToken } from '../../utils/cookies'

const headers = {
    'Authorization': `Bearer ${getToken()}`,
};

export const geVideoRecomendationListRequest = (page, limit, startDate, endDate, sort, keyword) => {
    let resp = null
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${geVideoRecomendationList(page, limit, startDate, endDate, sort, keyword)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const createVideoRequest = (data) => {
    let resp = null
    resp = axios({
        method: 'post',
        data: data,
        url: `${BaseUrl()}${createVideo()}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const getVideoByIdRequest = (id) => {
    let resp = null
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getVideoById(id)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const updateVideoRequest = (id, data) => {
    let resp = null
    resp = axios({
        method: 'put',
        data: data,
        url: `${BaseUrl()}${updateVideo(id)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const deleteVideoByIdRequest = (id) => {
    let resp = null
    resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${deleteVideoById(id)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

