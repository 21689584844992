<template>
  <v-row>
    <v-col md="12" lg="12" sm="12">
      <v-card outlined>
        <v-card-title class="text-sm font-bold">
          Trend Penjualan Tahun {{ year }}
        </v-card-title>
        <v-card-text>
          <VueApexCharts
            type="area"
            width="100%"
            height="250"
            :options="chartOptions"
            :series="series"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { monthNames, formatThousand } from "@/utils/helper.js";

export default {
  props: {
    loading: {
      default: false,
    },
    salesTrend: {
      type: Array,
      default() {
        return [];
      },
    },
    year: {
      default: "",
    },
  },
  data: () => ({}),
  computed: {
    series() {
      const chartData = this.salesTrend.map((item) => item.totalAmount);
      return [
        {
          name: "series",
          data: chartData,
        },
      ];
    },
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: this.salesTrend.map((item) => monthNames(item.month)),
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return formatThousand(value);
            },
          },
        },
        tooltip: {},
      };
    },
  },
  components: {
    VueApexCharts,
  },
};
</script>
