<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="500">
        <v-card class="rounded-3xl">
          <v-card-title class="text-base font-bold pb-10">
            <span>Detail Transaksi</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-card class="rounded-3xl">
              <v-card-title>
                <span class="text-base font-bold">Rincian Transaksi</span>
                <v-spacer></v-spacer>
                <div class="text-sm text-gray-kc-200">
                 {{ formateDate(data.createdAt) }}
                </div>
              </v-card-title>
              <v-card-text class="flex">
                <span class="align-middle">
                    <span class="text-xs">{{ data.invoice }}</span>
                    <v-icon>mdi-square-small</v-icon>
                    <span class="text-xs">{{ data.type }}</span>
                </span>
                <v-spacer></v-spacer>
                <div class="text-xs text-gray-kc-200">
                 {{ data.profile.business ? data.profile.business.name : "-"  }}
                </div>
              </v-card-text>
              <v-card-text class="flex">
                <v-text-field
                  label="Kategori Transaksi"
                  class="mr-5"
                  v-model="data.category"
                  readonly
                ></v-text-field>
                <v-text-field
                  label="Sub Kategori"
                  class="mr-5"
                  v-model="data.subCategory"
                  readonly
                ></v-text-field>
              </v-card-text>
              <v-spacer></v-spacer>
              <v-card-text>
                  <div class="p-3 flex bg-gray-kc-100 rounded-md">
                    <div class="text-base font-bold">Total</div>
                    <div class="text-base font-bold ml-auto">{{ formateRupiah(data.totalAmount) }}</div>
                  </div>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <SnackBar :snackbar="isError" :msg="errorCause" @close="closeError" />
  </div>
</template>
<script>
import { TransactionController } from "@/controllers/TransactionController";
import { dateToDateString, formatPrice } from "@/utils/helper.js";
import SnackBar from "@/views/Components/snackbar.vue";

export default {
  data: () => ({
    transaction: new TransactionController(false, false, ""),
    dialog: false,
  }),
  components: {
    SnackBar
  },
  computed: {
    data() {
      return this.transaction.detail;
    },
    isLoading() {
      return this.transaction.loadingDetail;
    },
    isError() {
      return this.transaction.errorDetail;
    },
    errorCause() {
      return this.transaction.errorCauseDetail;
    },
  },
  methods: {
    async getTransactionId(id) {
      return this.transaction.getTransactionById(id);
    },
    async openDialog(id) {
      this.dialog = true;
      await this.getTransactionId(id);
    },
    formateDate(date) {
      return dateToDateString(date);
    },
    closeError(status) {
      this.transaction.setErrorDetail(status);
    },
    formateRupiah(value) {
      return formatPrice(value);
    },
  },
};
</script>
<style scoped>
.v-sheet.v-card {
  border-radius: 10px;
}
</style>
