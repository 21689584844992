import moment from 'moment';

export const dateToDateString = (
    date,
    shortMonth = true,
    time = false
) => {
    const format = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09"];
    const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember"
    ];
    const shortMonthData = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Agu",
        "Sep",
        "Okt",
        "Nov",
        "Des"
    ];
    date = new Date(date);
    return date
        ? `${date.getDate().toString().length === 1
            ? format[date.getDate()]
            : date.getDate()
        } ${shortMonth ? shortMonthData[date.getMonth()] : month[date.getMonth()]
        } ${date.getFullYear()}${time ? `, ${dateToTime(date)}` : ""}`
        : "";
};

export const dateToTime = (date) => {
    const format = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09"];
    date = new Date(date);
    return `${date.getHours().toString().length === 1
            ? format[date.getHours()]
            : date.getHours()
        }:${date.getMinutes().toString().length === 1
            ? format[date.getMinutes()]
            : date.getMinutes()
        }`;
};

export const filterDates = (event) => {
    let startDate = null;
    let endDate = null;
    if(event === 0) {
        startDate = moment().format('YYYY-MM-DD')
        endDate = moment().format('YYYY-MM-DD')
    } else if(event === 1) {
        startDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
        endDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
    } else if(event === 2) {
        startDate = moment().subtract(6, 'days').format('YYYY-MM-DD')
        endDate = moment().format('YYYY-MM-DD')
    } else if(event === 3) {
        startDate = moment().subtract(31, 'days').format('YYYY-MM-DD')
        endDate = moment().format('YYYY-MM-DD')
    }

    return {
        startDate: startDate,
        endDate: endDate
    }
}

export const filterQuickDates = (event) => {
    console.log(event)
    let startDate = null;
    let endDate = null;
    if(event === 0) {
        startDate = moment().subtract(7, 'days').format('YYYY-MM-DD')
        endDate = moment().format('YYYY-MM-DD')
    } else if(event === 1) {
        startDate = moment().subtract(30, 'days').format('YYYY-MM-DD')
        endDate = moment().format('YYYY-MM-DD')
    } else if(event === 2) {
        startDate = moment().subtract(90, 'days').format('YYYY-MM-DD')
        endDate = moment().format('YYYY-MM-DD')
    }

    return {
        startDate: startDate,
        endDate: endDate
    }
}

export const formatPrice = (price) => {
    if (!price) return "Rp. 0";
    const result = price.toLocaleString("id");
    return `Rp. ${result}`;
};

export const formatThousand = (price) => {
    if (!price) return "0";
    const result = price.toLocaleString("id");
    return `${result}`;
};

export const monthNames = (val) => {
    const month = {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
    } 
    
    return month[val]
}
  
