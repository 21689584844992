<template>
  <v-dialog v-model="show" max-width="290" persistent>
    <v-card>
      <v-card-title class="text-h5"> {{ title === 'sukses' ? 'Sukses' : 'Gagal' }} </v-card-title>
      <v-card-text> {{ msg }} </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark rounded @click="close" class="title-case">
          Tutup
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    show: {
      default: true,
      type: Boolean,
    },
    title: {
      default: "sukses",
      type: String,
    },
    msg: {
      default: "",
      type: String,
    },
  },
  data: () => ({}),
  methods: {
    close() {
      this.$emit("close", false);
    },
  },
};
</script>
<style scoped>
.title-case {
  text-transform: none;
}
.v-application p {
  margin-bottom: 0px;
}
</style>