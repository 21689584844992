import axios from 'axios'
import { getFAQList, createFAQ, getFaqById, updateFaq, deleteFaqById } from '../endpoint/index'
import { BaseUrl } from '../endpoint/BaseUrl'
import { getToken } from '../../utils/cookies'

const headers = {
    'Authorization': `Bearer ${getToken()}`,
};

export const getFAQListRequest = (page, limit, keyword) => {
    let resp = null
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getFAQList(page, limit, keyword)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const createFAQRequest = (data) => {
    let resp = null
    resp = axios({
        method: 'post',
        data: data,
        url: `${BaseUrl()}${createFAQ()}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const getFaqByIdRequest = (id) => {
    let resp = null
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getFaqById(id)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const updateFaqRequest = (id, data) => {
    let resp = null
    resp = axios({
        method: 'put',
        data: data,
        url: `${BaseUrl()}${updateFaq(id)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

export const deleteFaqByIdRequest = (id) => {
    let resp = null
    resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${deleteFaqById(id)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}


