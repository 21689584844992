<template>
  <div class="mx-5 my-20">
    <v-row>
      <v-col lg="12" md="12">
        <v-alert dense outlined type="error">
          Page not found
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
