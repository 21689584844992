import { geNotificationListRequest, createNotificationRequest, getNotificationByIdRequest, updateNotifikasiRequest, deleteNotifikasiRequest, getNotificationDashboardListRequest } from '../api/request/NotificationRequest'

export class NotificationController {
    // list
    loadingList = false;
    errorList = false;
    errorCauseList = "";
    list = []

    // create
    loadingCreate = false;
    msgCreate = "";
    errorCreate = false;
    errorCauseCreate = "";

    // detail
    loadingDetail = false;
    errorDetail = false;
    errorCauseDetail = "";
    detail = {}

    // notif dasboard
    loadingListDashboard = false;
    errorListDashboard = false;
    errorCauseListDashboard = "";
    listDashboard = []
 
    constructor(loading, error, errorCause) {
        this.loadingList = loading;
        this.errorList = error;
        this.errorCauseList = errorCause;
    }

    geNotificationList(page, limit, startDate, endDate, sort, keyword) {
        this.setLoadingList(true)
        let resp = null;
        resp = geNotificationListRequest(page, limit, startDate, endDate, sort, keyword)
            .then((response) => {
                this.setNotificationList(response.data.data)
                this.setErrorCauseList("")
                this.setErrorList(false)
                return true
            }).catch((err) => {
                this.setNotificationList([])
                if (err.response.data.code === 404) {
                    this.setErrorCauseList('Data Tidak Ditemukan')
                    this.setErrorList(false)
                } else {
                    this.setErrorCauseList(err)
                    this.setErrorList(true)
                }
                return false
            }).finally(() => {
                this.setLoadingList(false)
            })
        return resp
    }


    // list
    setLoadingList(status) {
        this.loadingList = status;
    }

    setNotificationList(data) {
        this.list = data;
    }

    setErrorCauseList(err) {
        this.errorCauseList = err;
    }

    setErrorList(status) {
        this.errorList = status;
    }

    //create
    createNotification(data) {
        this.setLoadingCreate(true)
        let resp = null;
        resp = createNotificationRequest(data)
            .then((response) => {
                this.setMsgSuccess(response.data.message)
                this.setErrorCauseCreate("")
                this.setErrorCreate(false)
                return true
            }).catch((err) => {
                this.setMsgSuccess("")
                this.setErrorCauseCreate(err)
                this.setErrorCreate(true)
                
                return false
            }).finally(() => {
                this.setLoadingCreate(false)
            })
        return resp
    }

    updateNotifikasi(id, data) {
        this.setLoadingCreate(true)
        let resp = null;
        resp = updateNotifikasiRequest(id, data)
            .then((response) => {
                this.setMsgSuccess(response.data.message)
                this.setErrorCauseCreate("")
                this.setErrorCreate(false)
                return true
            }).catch((err) => {
                this.setMsgSuccess("")
                this.setErrorCauseCreate(err)
                this.setErrorCreate(true)
                
                return false
            }).finally(() => {
                this.setLoadingCreate(false)
            })
        return resp
    }

    deleteNotifikasi(id) {
        this.setLoadingCreate(true)
        let resp = null;
        resp = deleteNotifikasiRequest(id)
            .then((response) => {
                this.setMsgSuccess(response.data.message)
                this.setErrorCauseCreate("")
                this.setErrorCreate(false)
                return true
            }).catch((err) => {
                this.setMsgSuccess("")
                this.setErrorCauseCreate(err)
                this.setErrorCreate(true)
                
                return false
            }).finally(() => {
                this.setLoadingCreate(false)
            })
        return resp
    }

    setMsgSuccess(msg) {
        this.msgCreate = msg;
    }

    setLoadingCreate(status) {
        this.loadingCreate = status;
    }

    setErrorCauseCreate(err) {
        this.errorCauseCreate = err;
    }

    setErrorCreate(status) {
        this.errorCreate = status;
    }

    // detail
    getNotificationById(id) {
        this.setLoadingDetail(true)
        let resp = null;
        resp = getNotificationByIdRequest(id)
            .then((response) => {
                this.setNotifDetail(response.data.data)
                this.setErrorCauseDetail("")
                this.setErrorDetail(false)
                return true
            }).catch((err) => {
                this.setNotifDetail({})
                if (err.response.data.code === 404) {
                    this.setErrorCauseDetail('Data Tidak Ditemukan')
                    this.setErrorDetail(false)
                } else {
                    this.setErrorCauseDetail(err)
                    this.setErrorDetail(true)
                }
                return false
            }).finally(() => {
                this.setLoadingDetail(false)
            })
        return resp
    }

    setLoadingDetail(status) {
        this.loadingDetail = status;
    }

    setNotifDetail(data) {
        this.detail = data;
    }

    setErrorCauseDetail(err) {
        this.errorCauseDetail = err;
    }

    setErrorDetail(err) {
        this.errorDetail = err;
    }

    // notif dashboard 
    getNotificationDashboardList(page, limit) {
        this.setLoadingListDashboard(true)
        let resp = null;
        resp = getNotificationDashboardListRequest(page, limit)
            .then((response) => {
                this.setNotificationListDashboard(response.data.data)
                this.setErrorCauseListDashboard("")
                this.setErrorListDashboard(false)
                return true
            }).catch((err) => {
                this.setNotificationListDashboard([])
                if (err.response.data.code === 404) {
                    this.setErrorCauseListDashboard('Data Tidak Ditemukan')
                    this.setErrorListDashboard(false)
                } else {
                    this.setErrorCauseListDashboard(err)
                    this.setErrorListDashboard(true)
                }
                return false
            }).finally(() => {
                this.setLoadingListDashboard(false)
            })
        return resp
    }

    setLoadingListDashboard(status) {
        this.loadingListDashboard = status;
    }

    setNotificationListDashboard(data) {
        this.listDashboard = data;
    }

    setErrorCauseListDashboard(err) {
        this.errorCauseListDashboard = err;
    }

    setErrorListDashboard(status) {
        this.errorListDashboard = status;
    }
}
