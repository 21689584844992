<template>
  <v-main>
    <v-app>
      <div class="bg-gray-kc-100 h-screen">
        <Navbar />
        <Form />
      </div>
    </v-app>
  </v-main>
</template>

<script>
// @ is an alias to /src
import Navbar from "./components/Navbar.vue";
import Form from "./components/Form.vue";

export default {
  name: "Login",
  components: {
    Navbar,
    Form,
  },
};
</script>
