import axios from 'axios'
import { getReportAnalysis } from '../endpoint/index'
import { BaseUrl } from '../endpoint/BaseUrl'
import { getToken } from '../../utils/cookies'

const headers = {
    'Authorization': `Bearer ${getToken()}`,
};

export const getReportAnalysisRequest = (module, year, businessType) => {
    let resp = null
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getReportAnalysis(module, year, businessType)}`,
        headers:  headers,
    }).then((response) => {
        return response
    })
    return resp
}

