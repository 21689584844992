import { getTotalBuinessRequest, GetBusinessListRequest, getBusinessByIdRequest } from '../api/request/BusinessRequest'

export class BusinessController {
    loading = false;
    error = false;
    errorCause = "";
    businessData = [];

    // list
    loadingList = false;
    errorList = false;
    errorCauseList = "";
    list = []
    //List ngambil meta page API
    metaList=[]
    // detail
    loadingDetail = false;
    errorDetail = false;
    errorCauseDetail = "";
    detail = {}


    constructor(loading, error, errorCause) {
        this.loading = loading;
        this.error = error;
        this.errorCause = errorCause;
    }

    getTotalBuiness(startDate, endDate) {
        this.setLoading(true)
        let resp = null;
        resp = getTotalBuinessRequest(startDate, endDate)
            .then((response) => {
                this.setBusiness(response.data.data)
                this.setErrorCause("")
                this.setError(false)
                return true
            }).catch((err) => {
                
                if (err.response.data.code === 404) {
                    this.setErrorCause('Data Tidak Ditemukan')
                    this.setError(false)
                } else {
                    this.setErrorCause(err)
                    this.setError(true)
                }
                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }

    getBusinessList(page, limit, startDate, endDate, sort, keyword) {
        this.setLoadingList(true)
        let resp = null;
        resp = GetBusinessListRequest(page, limit, startDate, endDate, sort, keyword)
            .then((response) => {
                this.setBusinessList(response.data.data)
                //get meta page API
                this.setMetaList(response.data.meta)
                this.setErrorCauseList("")
                this.setErrorList(false)
                return true
            }).catch((err) => {
                this.setBusinessList([])
                if (err.response.data.code === 404) {
                    this.setErrorCauseList('Data Tidak Ditemukan')
                    this.setErrorList(false)
                } else {
                    this.setErrorCauseList(err)
                    this.setErrorList(true)
                }
                return false
            }).finally(() => {
                this.setLoadingList(false)
            })
        return resp
    }

    getBusinessById(id) {
        this.setLoadingDetail(true)
        let resp = null;
        resp = getBusinessByIdRequest(id)
            .then((response) => {
                this.setBusinessDetail(response.data.data)
                this.setErrorCauseDetail("")
                this.setErrorDetail(false)
                return true
            }).catch((err) => {
                this.setProfileList({})
                if (err.response.data.code === 404) {
                    this.setErrorCauseDetail('Data Tidak Ditemukan')
                    this.setErrorDetail(false)
                } else {
                    this.setErrorCauseDetail(err)
                    this.setErrorDetail(true)
                }
                return false
            }).finally(() => {
                this.setLoadingDetail(false)
            })
        return resp
    }

    setBusiness(data) {
        this.businessData = data;
    }

    setLoading(status) {
        this.loading = status;
    }

    setError(status) {
        this.error = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    // list
    setLoadingList(status) {
        this.loadingList = status;
    }

    setBusinessList(data) {
        this.list = data;
    }
     //set Meta page API
    setMetaList(meta){
        this.metaList=meta;
    }
    
    setErrorCauseList(err) {
        this.errorCauseList = err;
    }

    setErrorList(status) {
        this.errorList = status;
    }

    //detail
    setLoadingDetail(status) {
        this.loadingDetail = status;
    }

    setBusinessDetail(data) {
        this.detail = data;
    }

    setErrorCauseDetail(err) {
        this.errorCauseDetail = err;
    }

    setErrorDetail(err) {
        this.errorDetail = err;
    }
}
