<template>
  <div class="mx-5 my-20">
    <v-row>
      <v-col md="12" lg="12" sm="12">
        <p class="font-bold text-lg">Laporan Analisis</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="3" lg="3" sm="12" v-if="role !== 'owner'">
        <v-select
          label="Pilih Bidang Usaha"
          item-text="name"
          item-value="type"
          :items="businssTypes"
          v-model="businessType"
          flat
          dense
        ></v-select>
      </v-col>
      <v-col md="3" lg="3" sm="12">
        <v-select
          label="Pilih Tahun"
          v-model="year"
          flat
          dense
          :items="years"
        ></v-select>
      </v-col>
      <v-col md="2" lg="2" sm="12">
        <v-btn rounded class="title-case" dark @click="apply"> Terapkan</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        :md="`${role !== 'owner' ? '9' : '12'}`"
        :lg="`${role !== 'owner' ? '9' : '12'}`"
        sm="12"
      >
        <BigCard
          :role="role"
          :loading="isLoading"
          :sales="data.sales"
          :grossProfit="data.grossProfit"
          :cashOnHand="data.cashOnHand"
          :hpp="data.hpp"
        />
        <SmallCard
          :loading="isLoading"
          :accountReceivable="data.accountReceivable"
          :supply="data.supply"
          :accountPayable="data.accountPayable"
          :accountPayableKur="data.accountPayableKur"
        />
        <Chart
          :loading="isLoading"
          :salesTrend="data.salesTrend"
          :year="year"
        />
      </v-col>
      <Top
        v-if="role !== 'owner'"
        :loading="isLoading"
        :topBusiness="data.topBusiness"
        :topSales="data.topSales"
      />
    </v-row>
    <SnackBar :snackbar="isError" :msg="errorCause" @close="closeError" />
  </div>
</template>
<script>
import BigCard from "./components/BigCard.vue";
import SmallCard from "./components/SmallCard.vue";
import Chart from "./components/Chart.vue";
import Top from "./components/Top.vue";
import SnackBar from "@/views/Components/snackbar.vue";
import { ReportAnalysisController } from "@/controllers/ReportAnalysisController";
import { formatPrice } from "@/utils/helper.js";
import { getRole } from "@/utils/cookies.js";

export default {
  data: () => ({
    report: new ReportAnalysisController(false, false, ""),
    businessType: getRole() !== 'owner' ? 'retail' : '',
    year: 2023,
    years: [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
    businssTypes: [
      {
        type: "retail-perdagangan",
        name: "Retail (Perdagangan)",
      },
      {
        type: "handphone-pulsa",
        name: "Handphone / Pulsa",
      },
      {
        type: "alat-elektronik",
        name: "Alat Elektronik",
      },
      {
        type: "makanan-minuman",
        name: "Makanan dan Minuman",
      },
      {
        type: "alat-bahan-bangunan",
        name: "Alat Bahan Bangunan",
      },
      {
        type: "sembako",
        name: "Sembako",
      },
      {
        type: "otomotif",
        name: "Otomotif",
      },
      {
        type: "pakaian",
        name: "Pakaian",
      },
      {
        type: "kesehatan-kecantikan",
        name: "Kesehatan dan Kecantikan",
      },
      {
        type: "pendidikan-kursus",
        name: "Pendidikan / Kursus",
      },
      {
        type: "tour-travel",
        name: "Tour & Travel",
      },
      {
        type: "kerajinan-tangan",
        name: "Kerajinan Tangan",
      },
      {
        type: "jasa",
        name: "Jasa",
      },
      {
        type: "produksi",
        name: "Produksi",
      },
      {
        type: "pertanian",
        name: "Pertanian",
      },
      {
        type: "lain-lain",
        name: "Lain - lain",
      },
    ],
  }),
  components: {
    BigCard,
    SmallCard,
    Chart,
    Top,
    SnackBar,
  },
  computed: {
    isLoading() {
      return this.report.loadingList;
    },
    isError() {
      return this.report.errorList;
    },
    errorCause() {
      return this.report.errorCauseList;
    },
    data() {
      return {
        sales: formatPrice(this.report.data.sales),
        grossProfit: formatPrice(this.report.data.grossProfit),
        cashOnHand: formatPrice(this.report.data.cash),
        accountReceivable: formatPrice(this.report.data.accountReceivable),
        supply: formatPrice(this.report.data.supply),
        accountPayable: formatPrice(this.report.data.accountPayable),
        accountPayableKur: formatPrice(this.report.data.accountPayableKur),
        salesTrend: this.report.data.salesTrend,
        topBusiness: this.report.data.topBusiness ?? [],
        topSales: this.report.data.topSales ?? [],
        hpp: formatPrice(this.report.data.hpp ?? 0)
      };
    },
    role() {
      return getRole();
    },
  },
  created() {
    this.getData();
  },
  methods: {
    async getReportAnalysis(year, businessType) {
      return this.report.getReportAnalysis(getRole(), year, businessType);
    },
    async getData() {
      await this.getReportAnalysis(this.year, this.businessType);
    },
    closeError(status) {
      this.report.setErrorList(status);
    },
    async apply() {
      await this.getReportAnalysis(this.year, this.businessType);
    },
  },
};
</script>
<style scoped>
.title-case {
  text-transform: none;
}
.v-application p {
  margin-bottom: 0px;
}
</style>