<template>
  <div>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="mt-10 px-5 py-10 rounded-lg w-4/4">
            <v-card-text>
              <img
                class="pb-16 text-center block mx-auto w-2/4"
                :src="require(`@/assets/svg/logo.svg`)"
                alt="logo"
              />
              <v-alert v-if="isError" dense outlined type="error">
                {{ errorCause }}
              </v-alert>
              <v-form>
                <div class="py-5 flex text-left font-bold text-lg">
                  <h1>Masuk</h1>
                </div>
                <v-text-field
                  outlined
                  dense
                  label="Email"
                  type="text"
                  v-model="form.email"
                  @keyup.enter="login"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Password"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  @click:append="showPass = !showPass"
                  v-model="form.password"
                  @keyup.enter="login"
                ></v-text-field>
                <v-select
                  outlined
                  dense
                  label="Role"
                  :items="roles"
                  v-model="form.role"
                ></v-select>
              </v-form>
              <div class="py-5 flex text-right">
                <a href="#">Lupa Kata Sandi?</a>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                rounded
                @click="login"
                dark
                block
                class="title-case"
              >
                Masuk
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import { AuthControllers } from "@/controllers/AuthControllers.js";
export default {
  name: "Form",
  data: () => ({
    auth: new AuthControllers(false, false, ""),
    showPass: false,
    form: {
      email: "",
      password: "",
      role: "Admin"
    },
    roles: ["Admin", "Owner", "Third Party"]
  }),
  computed: {
    isError() {
      return this.auth.error;
    },

    errorCause() {
      return this.auth.errorCause;
    },

    isLoading() {
      return this.auth.loading;
    },
  },
  methods: {
    async goLogin(email, password, role) {
      return this.auth.signIn(email, password, role);
    },
    async login() {
      await this.goLogin(this.form.email, this.form.password, this.form.role);
      if (!this.isError) {
        window.location.href = window.location.origin;
      } 
    },
  },
};
</script>
<style scoped>
.flex {
  display: grid;
}
.v-sheet.v-card {
  border-radius: 12px;
}
.title-case {
  text-transform: none;
}
</style>